import { OutlinedInput } from "@material-ui/core";
import { useState } from "react";
import { Button, Col, Form, Row } from "react-bootstrap";
import styles from './FormRequestCode.module.scss';
import button from 'assets/Button/button.module.scss';
import { Auth } from "aws-amplify";

export default function FormRequestCode({username, setUsername, setCodeRequested}){

  async function handleSubmit(){
    setCodeRequested(true)
    try {
      await Auth.forgotPassword(username);

    } catch (error) {
      console.log(error)
    }
  }
  return(
    <>
        <Col xs={12} className={styles.form}>
          <OutlinedInput
            className={styles.inputStandard}
            type="text"
            placeholder="Usuário"
            value={username}
            onChange={(value) => setUsername(value.target.value)}
            style={{width: '100%'}}
          />
          <Row >
            <Col xs={12}>
              <Button 
                onClick={() => handleSubmit()}
                className={[button.colorWhite, button.width100]}
              >
                Solicitar código
              </Button>
            </Col>
          </Row>
        </Col>
    </>
  )
}