import React, { useEffect, useState } from 'react'
import Authorization from "components/Authorization";
import NotAuthorized from "components/NotAuthorized";
import { authorization } from 'service/Authorizarion';
import { API } from 'aws-amplify';
import { useNavigate } from 'react-router-dom';
import { Form } from 'react-bootstrap';

import Reload from '../../../assets/Icons/botao_reiniciar.js'
import Insert from 'assets/Icons/inserir.js';
import SearchMagnifier from 'assets/Icons/search_magnifier.js';
import SupportNavBar from '../SupportNavBar';
import SupportTable from './SupportTable/index.jsx';
import styles from './SupportClient.module.scss';
import styled from 'styled-components';

const SelectSearch = styled(Form.Select)`
background-color: #4FAAC5;
border-radius: 50px 0 0 50px;
width: fit-content;
margin: 0;
font-weight: 500;
color: #fff;
border: none;
cursor: pointer;
&:active{
  filter: brightness(105%);
}
&:hover{
  background-color: #3191AD;
}
`
const SearchButton = styled.div`
display: flex;
background-color: #4FAAC5;
border-radius: 0 50px 50px 0;
justify-content: center;
align-items: center;
padding: 0 10px;
cursor: pointer;
&:active{
  filter: brightness(105%);
}
&:hover{
  background-color: #3191AD;
}
`;

export default function SupportClient() {
  const [authorizated, setAuthorizated] = useState(authorization());
  const [currentPage, setCurrentPage] = useState(0);
  const [pages, setPages] = useState([""]);
  const [searchParam, setSearchParam] = useState('name');
  const [filter, setFilter] = useState('');
  const navigate = useNavigate();
  const pageSize = 20;
  const [supportLog, setSupportLog] = useState([{
    admin: String,
    product: String,
    question: String,
    requester: String,
    solution: String,
    timestamp: String,
    username: String
  }]);
  const [pagesButtons, setPagesButtons] = useState([]);
  const pagesNum = Math.ceil(supportLog.length / pageSize);

  function paginateArray(array, pageSize) {
    return array.reduce((acc, item, index) => {
      const pageIndex = Math.floor(index / pageSize);
      if (!acc[pageIndex]) {
        acc[pageIndex] = [];
      }
      acc[pageIndex].push(item);
      return acc;
    }, []);
  }

  async function getData(){
    try{
      const response = await API.get(
        "SmartlyAPI",
        `/support/reports`,
      );
      const sorted = response.body.response.sort(function(a, b){return b.timestamp - a.timestamp});
      return sorted;
    }catch(e){
      console.log(e);
    }
  }

  function updatePagesButtons() {
    for(let i = 1; i < pagesNum+1; i++) {
      setPagesButtons(pagesButtons => [...pagesButtons, i]);
    }
  }

  function handleSearch() {
    getData().then((logs) => {
      const filteredLogs = logs.filter((log) =>
      searchParam === 'name' ?
        log.requester.toLowerCase().includes(filter.toLowerCase())
      :
        log.username.toLowerCase().includes(filter.toLowerCase())
      )
      setSupportLog(filteredLogs);
    })
  }

  useEffect(() => {
    setPages(paginateArray(supportLog, pageSize));
  }, [supportLog])

  useEffect(() => {
    setPagesButtons([])
    updatePagesButtons();
  }, [pagesNum])

  useEffect(()=>{
    getData().then((logs) => {
      setSupportLog(logs)
    })
  }, [])

  return (
    <>
      <Authorization setAuthorizated={setAuthorizated} />
      {
        !authorizated ?
          <NotAuthorized />
        :
        <>
          <SupportNavBar/>
          <div className={styles.header}>
            <div className={styles.searchBox}>
              <SelectSearch
              value={searchParam}
              onChange={(select) => setSearchParam(select.target.value)}>
                <option value="name">Nome</option>
                <option value="username">Usuário</option>
              </SelectSearch>
              <Form.Control
              style={{
                backgroundColor: "#DADADA",
                borderRadius: 0,
                border: "2px solid #4FAAC5",
                width: "180px",
              }}
              type="text"
              value={filter}
              onChange={(e) => setFilter(e.target.value)}
              placeholder='Pequisar'
              />
              <SearchButton onClick={() => handleSearch()}>
                <SearchMagnifier />
              </SearchButton>
            </div>
            <h2 className={styles.title}>
              Registro de Atendimentos
            </h2>
            <div className={styles.toolBar}>
              <div className={styles.reloadBox}
                  onClick={() => {
                    getData().then((logs) => {
                      setSupportLog(logs)})}
                    }>
                <div className={styles.reload}>
                  <Reload/>
                </div>
              </div>
              <div className={styles.insertBox}
              onClick={() => {navigate("/main/support/client/insert")}}>
                <div className={styles.insert}>
                  Inserir <Insert/>
                </div>
              </div>
            </div>
          </div>
          {pages[0] && <SupportTable data={pages[currentPage]}/>}
          <div className={styles.pagesBar}>
            <div className={styles.pagesBox}>
              <div className={styles.prevNxtButton} onClick={() => currentPage > 0 && setCurrentPage(currentPage-1)}>
                Anterior
              </div>
              {pagesButtons.map((pageNumber) => {
                return (
                  <div className={styles.pageButton} key={pageNumber} onClick={() => setCurrentPage(pageNumber-1)} style={{border: pageNumber-1 === currentPage ? "solid 2px #00799C" : "none"}}>
                    {pageNumber}
                  </div>
                )
              })}
              <div className={styles.prevNxtButton} onClick={() => currentPage < pagesNum-1 && setCurrentPage(currentPage+1)}>
                Próxima
              </div>
            </div>
          </div>
        </>
      }
    </>
  )
}
