import { Auth } from "aws-amplify";
import React, {useEffect, useState} from "react";
import jwtDecode from "jwt-decode";

export default function ProtectPage({errorPage, targetPage, func}){

  const [page, setPage] = useState(<></>);

  var decode;
  if(sessionStorage.getItem('jwtToken'))
    decode = jwtDecode(sessionStorage.getItem('jwtToken'))
  
  async function renderPage(){
    try {
      if(!decode){
        setPage(errorPage)
        return
      }
      if(Number(decode.exp + "000") <= Date.now()){
        throw Error("Session expired")
      }
      setPage(targetPage);
    } catch (error) {
      console.log(error)
      setPage(errorPage)
    }
  }

  useEffect(async () => {
    await renderPage();
  }, [])

  return page;
}