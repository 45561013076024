import { IoT } from '@aws-sdk/client-iot';
import { Auth } from 'aws-amplify';
import { API } from './Api';

/**
 * create instance of IOT class
 * @returns {IoT} `iot` 
 */
export async function instanceIoT(){
  const user = await Auth.currentCredentials();
  var iot = new IoT(
    {
      region: 'us-east-1',
      credentials: 
      {
        accessKeyId: user.accessKeyId,
        secretAccessKey: user.secretAccessKey,
        expiration: user.expiration,
        sessionToken: user.sessionToken
      }
    }
  )
  return iot;
}

/**
 * @param {String} smartlyId 
 * @returns {String} group name 
 */
export async function getGroups(smartlyId){
  const iot = await instanceIoT();

  const { thingGroups } = await iot.listThingGroupsForThing({ thingName: smartlyId });
  return thingGroups[0].groupName;
}

/**
 * get device group in Dynamo DB
 */
  export async function getDeviceGroup(smartlyId){
  const group = await getGroups(smartlyId);
  return group;
}

/**
 * get device model in Dynamo DB
 */
export async function getModel(deviceId){
  const group = await getDeviceGroup(deviceId)
  return getDeviceModel(group);  
}

/**
 * Receive device group and convert in model for mini cards
 * @param {String} deviceGroup 
 * @returns `device model`
 */
export function getDeviceModel(deviceGroup){
  switch(deviceGroup){
    case 'wifi-display':
      return 'HF918';

    case 'HF810-A':
      return 'HF810';

    case 'HF900-A':
      return 'HF900';

    default:
      return deviceGroup
  }
}

/**
 * Receive device ID and search in DB owner user
 * @param {String} smartlyId
 * @returns owner user
 */
export async function getDeviceUserAndRegDate(smartlyId){
  return await API.get(
    "SmartlyAPI",
    `/users/`,
    {
      queryStringParameters:{
        smartlyId: smartlyId
      }
    }
  );
}