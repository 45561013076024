import { useState } from 'react';
import { Button, Card, Col, Form, Row, Spinner } from 'react-bootstrap';
import { CSVLink } from 'react-csv';

import { useSearchParams } from 'react-router-dom';
import LargeTable from './LogsList/index.jsx';
import { API } from "service/Api";

import button from 'assets/Button/button.module.scss'
import styles from './LogCard.module.scss'
import { useEffect } from 'react';

/**
 * card with device logs with the dates selected
 * @param {Device} device 
 * @returns {React.Component} `component`
 */
function LogCard({ device }) {
  const [dateStart, setDateStart] = useState('');
  const [dateEnd, setDateEnd] = useState('');
  const [logs, setLogs] = useState([]);
  const [loading, setLoading] = useState(false);
  const [disabledDownload, setDisabledDownload] = useState(true)
  const [firstSearch, setFirstSearch] = useState(true);
  const [page, setPage] = useState(0);
  const numberItemsForPage = 50;
  const [listToRender, setListToRender] = useState([]);
  const [params] = useSearchParams();
  const deviceName = params.get('deviceName');
  const [deviceId, setDeviceId] = useState('');
  const attributesSet = new Set();

  /**
   * get the logs device and set in useState logs
   */
  async function getLogs() {
    setLoading(true);
    try {
      const response = await API.get(
        "SmartlyAPI",
        `/devices/logs`,
        {
          queryStringParameters: {
            smartlyId: device.id,
            dateStart: dateStart,
            dateEnd: dateEnd
          },
        })
      setFirstSearch(false);
      setLoading(false);
      setDeviceId(device.id)
      setLogs(response.body.body.Items)
      console.log("LOGS Response:", logs)
      setDisabledDownload(false)
    } catch (error) {
      setFirstSearch(false);
      console.log(error)
      setLoading(false);
    }
  }

  logs.forEach(obj => {
    Object.keys(obj).forEach(key => {
      attributesSet.add(key);
    });
  });

  const fileHeaders = Array.from(attributesSet);
  // console.log("FILEHEDADERS ARRAY", fileHeaders )

  function handleBack() {
    if (page > 0)
      setPage(page - 1)
  }

  function handleNext() {
    setPage(page + 1);
  }

  useEffect(() => {
    const newList = logs.slice(page * numberItemsForPage, (page * numberItemsForPage + numberItemsForPage))
    setListToRender(newList);
  }, [logs, page])

  return (
    <Col className={styles.card}>
      <Card>
        <Card.Header className={styles.cardHeader}>
          <Card.Title> Últimas modificações</Card.Title>
        </Card.Header>
        <Card.Body>
          <Form>
            <Row sm={12} xs={12}>
              <Col sm={5} xs={12} className={styles.inputForm}>
                <Form.Label> Data inicial </Form.Label>
                <Form.Control
                  type='date'
                  value={dateStart}
                  onChange={(e) => setDateStart(e.target.value)}
                />
              </Col>
              <Col sm={5} xs={12} className={styles.inputForm}>
                <Form.Label> Data final </Form.Label>
                <Form.Control
                  type='date'
                  value={dateEnd}
                  onChange={(e) => setDateEnd(e.target.value)}
                />
              </Col>
              <Col sm={2} xs={12} className={[styles.buttonSearch, styles.inputForm]}>
                <Button className={[button.colorWhite, button.width100]} onClick={() => getLogs()}>
                  {
                    loading ?
                      <Spinner style={{ height: "20px", width: "20px" }}
                        animation="border" variant="light" /> : 'Pesquisar'
                  }
                </Button>
              </Col>
            </Row>
          </Form>
          {listToRender.length > 0 ?
            <LargeTable logs={listToRender} /> : !firstSearch && 'Nenhum registro encontrado'}
          <div className={styles.buttonsPagination}>
            <Button
              className={button.colorWhite}
              onClick={() => handleBack()}
              disabled={page < 1}
            >Anterior</Button>
            <Button
              className={button.colorWhite}
              onClick={() => handleNext()}
              disabled={page >= Math.ceil(Number(logs.length / numberItemsForPage)) - 1}
            >Próxima</Button>
            <Button disabled={disabledDownload} >
              <CSVLink className={`${button.colorWhite} ${button.decorationNone}`}
                filename={`${deviceName}_${deviceId}_${dateStart}_${dateEnd}.csv`}
                data={logs} headers={fileHeaders}>
                Baixar CSV
              </CSVLink>
            </Button>
          </div>
        </Card.Body>
      </Card>
    </Col>
  );
}

export default LogCard;