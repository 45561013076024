import React, { useState, useEffect } from "react";
import { Card, Col, Row, Button, Form } from "react-bootstrap";
import { mdiHandPointingUp } from "@mdi/js";
import Slider from "rc-slider";
import Icon from "@mdi/react";
import {
  KeyboardArrowDownOutlined,
  KeyboardArrowUpOutlined,
} from "@material-ui/icons";
import { createSearchParams, useNavigate } from "react-router-dom";
import { useContext } from "react";

import { isConnected, statusWifi, verifyStatus } from "utils/Cards";
import { initialGauging, isDeviceAdd } from "utils/Device";
import { getModel } from "service/Device";

import { AlertContext } from "context/alert";
import { SupportContext } from "context/support";

import styles from "./MiniThermostat.module.scss";
import button from 'assets/Button/button.module.scss';

/**
 * build the small thermostat card configuration
 * @returns {React.Component} `component`
 */
function MiniThermostat({ device: deviceInfos, user }) {
  const { setErrorAlert, setSuccessAlert, setShowAlert, setMessageAlert } = useContext(AlertContext)
  const { addDevicesToLocalStorage } = useContext(SupportContext);
  const [deviceId, setDeviceId] = useState("-");
  const [deviceWifi, setDeviceWifi] = useState("-");
  const [deviceSetPoint, setDeviceSetPoint] = useState("-");
  const [modifySetPoint, setModifySetPoint] = useState(0);
  const [isSubscribed, setIsSubscribed] = useState(false);
  const [isDropDownOpen, setIsDropDownOpen] = useState(false);
  const [gauging, setGauging] = useState(initialGauging(deviceInfos.device.temp));
  const [power, setPower] = useState(false);
  const [tempMax, setTempMax] = useState(5);
  const [tempMin, setTempMin] = useState(35);
  const [model, setModel] = useState('');
  const navigate = useNavigate();
  
  /**
   * create configuration to get device config  
   * @param { Device | callback} res 
   */
  function startConfig(res){
    if(Object.keys(res.config).length > 0){
      setTempMin(res.config.tempRange[0])
      setTempMax(res.config.tempRange[1])
    }
    setDeviceWifi(res.status === "connected" ? res.wifi : -1);
    setDeviceSetPoint(res.setPoint);
    setModifySetPoint(deviceInfos.device.setPoint);
    setGauging(initialGauging(res, deviceInfos.device.temp))
    setPower(deviceInfos.device.power)
  }

  useEffect(() => {
    async function getDeviceModel(){
      if(deviceInfos){
        setModel(await getModel(deviceInfos.id));
      }
    }
    
    setDeviceId(deviceInfos.id);

    startConfig(deviceInfos)
    deviceInfos.getConfig();
    setIsSubscribed(true);

    getDeviceModel()

    deviceInfos.callback((status) => {
      startConfig(status)
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deviceInfos]);

  function handleDropDown(){
    setIsDropDownOpen(!isDropDownOpen);
  }

  /**
   * veryfy device infos and return if infos was changed
   */
  function isAlterInfos(){
    if( tempMin === deviceInfos.config.tempRange[0] &&  
        tempMax === deviceInfos.config.tempRange[1] &&
        power === deviceInfos.device.power &&
        modifySetPoint === deviceInfos.device.setPoint &&
        gauging === deviceInfos.device.gauging 
      ){
        return false
      }else{
        return true
    }
  }

  /**
   * manager update config and show message success or error
   */
  async function handleUpdateConfig(){    
    if(!isAlterInfos()){
      setMessageAlert('Nenhuma informação alterada');
      setErrorAlert(true);
      setShowAlert(true);
      return      
    }   

    if(!isDeviceAdd(deviceInfos))
      addDevicesToLocalStorage(deviceInfos.id)

    const oldInfos = {
      config: {
        tempRange: [tempMin, tempMax],
        gauging: gauging
      },
      power: power,
      setPoint: deviceSetPoint
    }
    
    deviceInfos.updateSetpoint(modifySetPoint); 
    deviceInfos.updateConfig(
      {
        power: power,
        gauging: gauging
      }
    );

    var count = 0;
    var idInterval = setInterval(async ()=>{ 

      if( 
          oldInfos.config.tempRange[0] !== deviceInfos.config.tempRange[0] ||  
          oldInfos.config.tempRange[1] !== deviceInfos.config.tempRange[1] ||
          oldInfos.power !== deviceInfos.device.power ||
          oldInfos.setPoint !== deviceInfos.device.setPoint ||
          oldInfos.config.gauging !== deviceInfos.config.gauging             
        ){
          setMessageAlert('Dispositivo atualizado com sucesso');
          setSuccessAlert(true);
          setShowAlert(true)
          clearTimeout(idInterval);          
        }        
        if(count>5){
          setMessageAlert('Erro ao atualizar o dispositivo');
          setErrorAlert(true);
          setShowAlert(true);
          clearTimeout(idInterval);
        }
      count++
    }, 1000);
  }

  return (
    <>
      <Col sm={4} xs={12}>              
        <Card className={styles.card}>
          <Card.Header className={styles.header} style={verifyStatus(deviceInfos, isSubscribed)}>
            <Card.Title 
              className={styles.title}
              onClick={() => 
                navigate({
                pathname: "device/",
                search: createSearchParams(
                  {
                    username: user.username,
                    email: user.email,
                    name: user.name,
                    userId: user.userId,
                    smartlyId: deviceInfos.id,
                    deviceId: deviceInfos.deviceId,
                    localName: deviceInfos.localName,
                    localId: deviceInfos.local_id,
                    deviceName: deviceInfos.name
                  }
                ).toString()
                })}
              >
              <div className={styles.fontTitle}>
                <span className={styles.deviceName}>{deviceInfos.name} </span> - (
                <span> {deviceId}</span> )
                <span> - {String(model)}</span>
              </div>
            </Card.Title>
          </Card.Header>          
          <Card.Body className={styles.cardBody}>
            <div>
              <Row>
                <div className={ isConnected(isSubscribed, deviceInfos) ? styles.cardBodyText : styles.cardBodyTextDisconnect}>
                  {statusWifi(isSubscribed, deviceWifi)}
                  <span style={{ fontWeight: "600" }}>
                    {isNaN(deviceInfos.device.temp) ? '-' : Number(deviceInfos.device.temp).toFixed(1)} / 
                    {isNaN(deviceSetPoint)? '-' : Number(deviceSetPoint).toFixed(1)} ºC
                  </span>
                  {deviceInfos.device.away ? 
                    <div className={styles.travel}>
                      <span class="material-symbols-outlined" style={{ fontSize: '28px'}}>
                        travel
                      </span>
                    </div>
                    :
                    (
                      deviceInfos.device.prog === 1 ? (
                        <Icon path={mdiHandPointingUp} size={1} />
                        ) : (deviceInfos.device.manualSetFlag ?
                          (
                          <div>
                            <Icon path={mdiHandPointingUp} size={0.75} className={styles.handPointingUp}/>
                            <div className={styles.calendar}>
                              <span className="material-symbols-outlined" style={{ fontSize: '18.5px'}}>
                                calendar_month
                              </span>
                            </div>
                          </div>
                          ) : 
                          (
                          <span className="material-symbols-outlined">
                            calendar_month
                          </span> 
                          )
                        )
                    )
                  }
                  <span
                    className="material-symbols-outlined"
                    style={
                      deviceInfos.device.power === 1 ? {} : { color: 'red' }
                    }
                  >
                    power_settings_new
                  </span>
                  <div className={styles.arrowDown} onClick={() => {handleDropDown()}}>
                    {isDropDownOpen? 
                    <KeyboardArrowUpOutlined /> :
                    <KeyboardArrowDownOutlined />
                  }
                  </div>
                </div>
              </Row>
              {isDropDownOpen &&
              <>
                <Row className={styles.rowCenter}>
                  <Form.Switch 
                    className={styles.formSwitch}
                    label="Power"
                    checked={Boolean(power)}
                    onChange={() => setPower(!power)}
                  />
                </Row>
                <Row className={styles.rowSlider}>
                  <Row className={styles.gauging}>
                    Set-Point
                  </Row>
                  <div className={styles.dropDownOpen}>
                    <Slider 
                      className={styles.slider}
                      onChange={(e) => setModifySetPoint(e)}
                      step={0.5}
                      min={tempMin}
                      max={tempMax}
                      value={modifySetPoint? modifySetPoint : 0}
                    />
                    <Card.Text>
                      {Number(modifySetPoint).toFixed(1)} ºC
                    </Card.Text>
                  </div>
                </Row>
                <Row className={styles.gauging}>
                  Aferir Sensor
                </Row>
                <Row className={styles.rowSlider}>
                  <div className={styles.dropDownOpen}>
                    <Slider 
                      className={styles.slider}
                      onChange={(e) => setGauging(e)}
                      step={0.5}
                      min={-5}
                      max={+5}
                      value={gauging? gauging : 0}
                    />
                    <Card.Text>
                      {`${Math.sign(Number(gauging).toFixed(1)) === 1 ? "+" : ""}${Number(gauging).toFixed(1)} ºC`}
                    </Card.Text>
                  </div>
                </Row>
                <Row>
                  <div className={styles.dropDownOpen}>
                    <Button 
                      className={button.colorWhite}
                      onClick={() => handleUpdateConfig() }
                    >
                    Enviar
                    </Button>
                  </div>
                </Row>
              </>
              }
            </div>
          </Card.Body>
        </Card>
      </Col>
    </>
  );
}

export default MiniThermostat;