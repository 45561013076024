import React, { useState, useContext, useEffect } from "react";
import { 
  Button, 
  Col, 
  Container, 
  Form, 
  Row, 
  Spinner, 
  Alert 
} from "react-bootstrap";

import { IoT } from "@aws-sdk/client-iot";
import { DeviceList } from "components";
import { subscribe, publish } from "service/Mqtt";
import Devices from "context/devices";
import { authorization } from 'service/Authorizarion';

import Authorization from "components/Authorization";
import NotAuthorized from "components/NotAuthorized";

import styles from './SearchDevice.module.scss';
import button from 'assets/Button/button.module.scss';
import { getDeviceBySmartlyID } from "service/Api";

import awsExports from "aws-exports";

/**
 * build the page search device
 * @returns {React.Component} `component`
 */
export default function SearchDevice(){

  const {device, addDevice, updateDevice} = useContext(Devices);
  const [id, setId] = useState("");
  const [type, setType] = useState("Tipo");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(
    {
      size: false,
      notFound: false
    }
  );

  const [authorizated, setAuthorizated] = useState(authorization());

  /**
   * @param {String} smartlyId 
   */
  async function getStateDeviceShadow(smartlyId = ""){
    function getDeviceType(){
      if(smartlyId.indexOf("SmartLy-th-") !== -1)
        return "thermostat";
      else if(smartlyId.indexOf("SmartLy-hc-") !== -1)
        return "hotcloset";
      else if(smartlyId.indexOf("SmartLy-lg-") !== -1)
        return "Lighting";
    }
    function checkDevice(){
      return device.filter((value) => {
          return value.id === smartlyId;
        }
      );
    }

    if(!checkDevice().length){
      const res = await getDeviceBySmartlyID(smartlyId);
      const subGet = subscribe(`$aws/things/${smartlyId}/shadow/name/${getDeviceType()}/get/accepted`, 
        (data) => {
          if(!checkDevice().length){
            const subDelta = subscribe(`$aws/things/${smartlyId}/shadow/name/${getDeviceType()}/update/delta`,
              (data) => {
                updateDevice(data, smartlyId);
                publish(`$aws/things/${smartlyId}/shadow/name/${getDeviceType()}/update`, {
                  state: { reported: data.value.state }
                }); 
              }
            );
            addDevice({
              id: smartlyId,
              state: data.value.state.desired,
              sub: subDelta,
              index: device.length,
              deviceId: res?.data[0]?.deviceId
            });
          }
          subGet.unsubscribe();
          setLoading(false);
        }
      );
      setTimeout(() => {
        publish(`$aws/things/${smartlyId}/shadow/name/${getDeviceType()}/get`); 
        setTimeout(() => {
          subGet.unsubscribe();
          setLoading(false);
        }, 3000);
      }, 2000);
    }else{
      setLoading(false);
    }
  }

  /**
   * get device based in id and add in device list
   * @param {React.FormEvent} event 
   */
  async function getDeviceIoTCore(event){
    event.preventDefault();
    setLoading(true);
    if(id.length === 12){
      try{
        const Client = new IoT(
          {
            region: "us-east-1",
            credentials:{
              accessKeyId: awsExports.accessKeyId,
              secretAccessKey: awsExports.secretAccessKey
            }
          }
        );
        const thing = await Client.describeThing(
          {
            thingName: type+id
          }
        );

        // console.log("Thing retrieved---------------------------:", thing);

        getStateDeviceShadow(thing.thingName);
        setError({size: false, notFound: false});
      }catch{
        setLoading(false);
        setError({size: false, notFound: true});
      }
    }else{
      setLoading(false);
      setError({size: true, notFound: false});
    }
  }

  return (
    <>
      <Authorization setAuthorizated={setAuthorizated} />
      {
        !authorizated ?
          <NotAuthorized />
        :
        <>
          <Form className={styles.searchDeviceForm} onSubmit={getDeviceIoTCore}>
            <Container>
              <h2 className={styles.title}>
                Buscar dispositivo
              </h2>
              <Col className={styles.searchDeviceButton} xs={12}>
                {
                  error.size || error.notFound ? 
                    <Alert 
                      variant="danger"
                    >
                      {
                        error.size ? <>O ID do dispositivo deve conter todos os caracteres!<br/></>: ""
                      }
                      {
                        error.notFound ? <>Dispositivo não encontrado!<br/></> : ""
                      }
                    </Alert>
                  :
                    <></>
                }
              </Col>
              <Row className={styles.searchDeviceBar}>
                <Col sm={2} xs={5}>
                  <Form.Select
                    value={type}
                    onChange={(select) => setType(select.target.value)}
                  >
                    <option value="">Tipo</option>
                    <option value="SmartLy-th-">Termostato</option>
                    <option value="SmartLy-hc-">HotCloset</option>
                    {/* <option value="SmartLy-lg-">Lighting</option> */}
                  </Form.Select>
                </Col>
                <Col sm={3} xs={7}>
                  <Form.Control 
                    type="text"
                    placeholder="Ex: FFFFFFFFFFFF"
                    value={id}
                    onChange={(input) => setId(input.target.value.toUpperCase())}
                  />
                </Col>
              </Row>
              <Col className={styles.searchDeviceButton} sm={3} xs={7}>
                <Button
                  className={[button.colorWhite, button.width100]}
                  type="submit"
                  disabled={loading}
                >
                  {
                    loading ?
                      <Spinner 
                        style={{height: "20px", width: "20px"}}
                        animation="border" 
                        variant="light" 
                      />
                    :
                      "Buscar"
                  }
                </Button>
              </Col>
            </Container>
          </Form>
        <DeviceList />
      </>
      }
    </>
  );
}