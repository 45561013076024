export const support = [
  {
    describe: 'Termostato muda de temperatura sozinho',
    solution: [ 
      'Verificar se o dispositivo está executando uma rotina padrão',
      'Verifique se a rotina foi criada pelo cliente ou uma rotina padrão do sistema'
    ]
  }, {
    describe: 'Aplicativo não responde comandos',
    solution: [ 
      'Verificar se o dispositivo está conectado na internet',
      'Verificar se o dispositivo está ligado no sistema'
    ]
  }, {
    describe: 'Piso não está aquecendo',
    solution: [ 
      'Verificar junto com o cliente se o relê está acionando (Barulho/Luz)',
      'Acompanhar log de funcionamento'
    ]
  }, {
    describe: 'Temperatura muito diferente da Real',
    solution: [ 
      'Verificar se está utilizando sensor de Piso/Ambiente',
      'Se disponivel, comparar a temperatura com outro termostato próximo',
      'Último caso, aferir temperatura do sensor'
    ]
  }, {
    describe: 'Luz do display está apagada ou Fraca',
    solution: [ 
      'Verificar configuração da intensidade do LED. Caso já esteja em 10, altere para um valor menor e em seguida volte para o valor 10 para resincronizar',
      'Verificar se o cliente está em um ambiente escuro'
    ]
  }, {
    describe: 'Dispositivo está travando quando acionado',
    solution: [ 
      'Verificar potência do ambiente',
      'Verificar se foi instalado contatora (necessário acima de 3000W) e filtro'
    ]
  }, {
    describe: 'Luz azul piscando (dispositivo desconectado)',
    solution: [ 
      'Verificar sinal de internet do cliente',
      'Verificar último horário de atualização do status (data / hora)'
    ]
  }, {
    describe: 'Erro "Dispositivo já cadastrado"',
    solution: [ 
      'Verificar em qual conta o dispositivo está cadastrado'
    ]
  },
]