import React, { useContext, useEffect, useState } from 'react'
import Authorization from "components/Authorization";
import NotAuthorized from "components/NotAuthorized";
import { authorization } from 'service/Authorizarion';
import { API } from 'aws-amplify';
import { useNavigate } from 'react-router-dom';
import Form from 'react-bootstrap/Form';
import { getAccount } from 'service/Authorizarion'

import ArrowBack from 'assets/Icons/arrow_back';
import DBrectangle from 'assets/Icons/dark_blue_rectangle';
import LBrectangle from 'assets/Icons/light_blue_rectangle';
import styles from './InsertCall.module.scss';
import SupportNavBar from 'components/Support/SupportNavBar';
import { ListGroup } from 'react-bootstrap';
import { AlertContext } from 'context/alert/index.js';
import AlertMessage from 'components/AlertMessage';

export default function InsertCall() {
  const [authorizated, setAuthorizated] = useState(authorization());
  const [users, setUsers] = useState([]);
  const [showUserList, setShowUserList] = useState(false);
  const [selectedUsername, setSelectedUsername] = useState('');
  const [admin, setAdmin] = useState('');
  const [product, setProduct] = useState('');
  const [question, setQuestion] = useState('');
  const [requester, setRequester] = useState('');
  const [solution, setSolution] = useState('');
  const navigate = useNavigate();
  const { showAlert, setShowAlert, setErrorAlert, setSuccessAlert, setMessageAlert } = useContext(AlertContext);


  async function getUsers(){
    try{
      const response = await API.get(
        "SmartlyAPI",
        `/users`,
      );
      setUsers(response);
    }catch(e){
      console.log(e);
    }
  }

  function updateUsername(value){
    setSelectedUsername(value);
    setShowUserList(false);
  }

  function showList() {
    setShowUserList(true);
    console.log("input showuserlist", showUserList)
  }

  function containerClick() {
    showUserList && setShowUserList(false);
    console.log("container showuserlist", showUserList)
  }

  function resetFields() {
    setRequester('');
    setSelectedUsername('');
    setProduct('');
    setQuestion('');
    setSolution('');
  }

  function handleSucess() {
    setMessageAlert("Atendimento adicionado com sucesso!");
    setSuccessAlert(true);
    setShowAlert(true);
    resetFields();
  }

  function handleError() {
    setMessageAlert("Usuário APP inválido!");
    setErrorAlert(true);
    setShowAlert(true);
  }

  async function handleSubmit(){
    if(isValidUsername(selectedUsername)){
      sendReport();
      handleSucess();
    } else {
      handleError();
    }
  }

  async function sendReport() {
    try{
      await API.post(
        "SmartlyAPI",
        `/support/reports`,{
        body: {
          username: selectedUsername,
          admin: admin,
          product: product,
          question: question,
          requester: requester,
          solution: solution,
        }
      });
    }catch(e){
      console.log(e);
    }
  }

  function isValidUsername(username) {
    return ((users.map((user) => user.username === username)).includes(true))
  }

  useEffect(() => {
    getUsers();
    getAccount().then((value) => setAdmin(value));
  }, [])

  return (
    <>
      <Authorization setAuthorizated={setAuthorizated} />
      {
        !authorizated ?
          <NotAuthorized />
        :
        <>
          <SupportNavBar/>
          <div>
            <div className={styles.header}>
              <div className={styles.return}
              onClick={() => {navigate("/main/support/client")}}>
                <ArrowBack/>
              </div>
              <h2 className={styles.title}>
                Inserir Novo Atendimento
              </h2>
            </div>
            {showAlert && 
            <div className={styles.alertMessage}>
              <AlertMessage/>
            </div>
            } 
          </div>
          <div className={styles.container} onClick={() => containerClick()}>
            <div className={styles.info}>
              <div className={styles.infoHeader}>
                <DBrectangle/> Dados
              </div>
              <Form className={styles.infoForm}>
                <Form.Group className="mb-3">
                  <Form.Label className={styles.inputLabel}>Solicitante</Form.Label>
                  <Form.Control className={styles.infoInput} 
                  type="text" 
                  placeholder='Ex: João da Silva'
                  value={requester}
                  onChange={(e) => setRequester(e.target.value)}
                  />
                  <div style={{position: 'relative'}}>
                    <Form.Label className={styles.inputLabel}>Usuário APP</Form.Label>
                    <Form.Control 
                    className={styles.infoInput}
                    type="text"
                    onClick={() => showList()}
                    value={selectedUsername}
                    onChange={(e) => setSelectedUsername(e.target.value)}
                    placeholder='Ex: joaosilva'
                    />
                    <ListGroup className={styles.usersList} hidden={!(showUserList && selectedUsername)}>
                    {selectedUsername &&
                      (() => {
                        const filteredUsers = users.filter((user) =>
                          user.username.toLowerCase().includes(selectedUsername.toLowerCase())
                        )
                        if (filteredUsers.length === 0){
                          return(
                            <ListGroup.Item className={styles.usersListItemEmpty}>
                              Nenhum resultado encontrado
                            </ListGroup.Item>
                          )
                        } 
                        
                        return filteredUsers.map((user) => (
                            <ListGroup.Item
                              key={user.username}
                              className={styles.usersListItem}
                              onClick={() => updateUsername(user.username)}
                            >
                              {user.username}
                            </ListGroup.Item>
                          ));
                        })()}
                    </ListGroup>
                  </div>
                  <Form.Label className={styles.inputLabel}>Produto</Form.Label>
                  <Form.Control
                  className={styles.infoInput }
                  type="text"
                  placeholder='Ex: HF810'
                  value={product}
                  onChange={(e) => setProduct(e.target.value)}
                  />
                </Form.Group>
              </Form>
              <div>
                <div className={styles.insertButton} onClick={() => handleSubmit()} type='submit'>Adicionar Atendimento</div>
                <div className={styles.cancelButton} onClick={() => {navigate("/main/support/client")}}>Cancelar</div>
              </div>
            </div>
            <div className={styles.questionSolution}>
            <Form.Group className="mb-3">
              <div className={styles.containerQS}>
              <Form.Label className={styles.labelQS}>
                <LBrectangle/> Questionamento
              </Form.Label>
              <Form.Control
              className={styles.inputQS}
              type="text"
              as='textarea'
              placeholder='Digite o questionamento do cliente.'
              value={question}
              onChange={(e) => setQuestion(e.target.value)}
              />
              </div>
              <div className={styles.containerQS}>
              <Form.Label className={styles.labelQS}>
                <LBrectangle/> Solução
              </Form.Label>
              <Form.Control
              className={styles.inputQS}
              type="text"
              as='textarea'
              placeholder='Digite a solução encontrada para o questionamento do cliente.'
              value={solution}
              onChange={(e) => setSolution(e.target.value)}/>
              </div>
            </Form.Group>
            </div>
          </div>
        </>
      }
    </>
  )
}
