import React, { useState, useEffect, useContext } from 'react';
import { Button, Card, Col, Form, Modal, Row, Spinner, Stack } from 'react-bootstrap';
import { FaRegCopy } from "react-icons/fa";
import { useSearchParams } from 'react-router-dom';
import QRCode from 'react-qr-code';
import { GetApp } from '@material-ui/icons';

import DeviceCardHeader from './DeviceCardHeader';
import ThermostatCardBody from './ThermostatCardBody';
import HotClosetCardBody from './HotClosetCardBody';

import { isDeviceAdd } from 'utils/Device';

import { AlertContext } from 'context/alert';
import { InfosDeviceContext } from 'context/device';
import { SupportContext } from 'context/support';

import styles from './DeviceCardConfig.module.scss';
import button from 'assets/Button/button.module.scss';
import { getUUID } from 'service/Api';

import { CreateJobCommand, IoTClient, DescribeJobCommand, ListJobExecutionsForThingCommand, JobExecution, JobExecutionSummary } from '@aws-sdk/client-iot';
import awsExports from 'aws-exports.js';

import Colors from 'assets/Colors';

/**
 * build device card config
 * @returns {React.Component} `component`
 */
function DeviceCardConfig({ deviceInfos }) {
  const { setErrorAlert, setSuccessAlert, setShowAlert, setMessageAlert } = useContext(AlertContext);
  const {
    power,
    setPower,
    deviceWifi,
    deviceSetPoint,
    tempMin,
    tempMax,
    modifySetPoint,
    setModifySetPoint,
    ledIntensity,
    awayTemp,
    gauging,
    deviceGroup,
    versionInstalledFirmware,
    prog
  } = useContext(InfosDeviceContext);

  const { addDevicesToLocalStorage } = useContext(SupportContext);
  const [modalOpen, setModalOpen] = useState(false);
  const [uuid, setUuid] = useState();

  const [loadingQRCode, setLoadingQRCode] = useState(true);
  const [loadingReboot, setLoadingReboot] = useState(true);

  const [params] = useSearchParams();
  const [wifiSettings, setWifiSettings] = useState({ ssid: "unknown", password: "unknown" });

  const [updatedFirmware, setUpdatedFirmware] = useState(false);
  const [latestVersion, setLatestVersion] = useState('');
  const [updating, setUpdating] = useState(false);
  const [copied, setCopied] = useState(false)
  
  const handleQRcodeDownload = () => {
    const svg = document.getElementById("QRCode");
    const svgData = new XMLSerializer().serializeToString(svg);
    const canvas = document.createElement("canvas");
    const ctx = canvas.getContext("2d");
    const img = new Image();
    img.onload = () => {
      canvas.width = img.width;
      canvas.height = img.height;
      ctx.drawImage(img, 0, 0);
      const pngFile = canvas.toDataURL("image/png");
      const downloadLink = document.createElement("a");
      downloadLink.download = params.get('smartlyId');
      downloadLink.href = `${pngFile}`;
      downloadLink.click();
    };
    img.src = `data:image/svg+xml;base64,${btoa(svgData)}`;
  };

  /**
   * Verify if device is thermostat
   * @param {String} id 
   * @returns {boolean} `bool`
   */
  function isThermostat(id) {
    if (id.includes('th'))
      return true
    return false
  }

  /**
   * get the device group and verify model and return it
   * @returns {String} `string`
   */
  function returnDeviceModel() {
    switch (deviceGroup) {
      case 'wifi-display':
      case 'HF918':
        return 'HF918';

      case 'HF801':
        return 'HF801';

      case 'HF801V2':
        return 'HF801V2';

      case 'HF810':
      case 'HF900':
        return 'hybrid';

      case 'HF810-A':
      case 'HF900-A':
        return 'Hybrid_A';

      case 'HF810V2':
      case 'HF900V2':
        return 'hybridV2';

      case 'HF918V2':
        return 'HF918V2';

      case 'HC405':
        return 'HC405';

      case 'HC405V2':
        return 'HC405V2';

      default:
        return deviceGroup
    }
  }

  /**
 * get the device group and set the correct path
 * @returns {String} `string`
 */
  function returnDevicePath() {
    switch (deviceGroup) {
      case 'HF801':
        return 'HF801';

      case 'HF801V2':
        return 'HF801V2';

      case 'HF810':
      case 'HF900':
      case 'HF810-A':
      case 'HF900-A':
        return 'Hybrid';

      case 'HF810V2':
      case 'HF900V2':
        return 'HYBRIDV2';

      case 'HF918':
        return 'HF918';

      case 'HF918V2':
        return 'HF918V2';

      case 'HC405':
        return 'HC405';

      case 'HC405V2':
        return 'HC405V2'

      default:
        return deviceGroup
    }
  }

  /**
   * get params smartly id and return type
   * @returns {String} `string`
   */
  function returnDeviceType() {
    const smartlyId = params.get('smartlyId');
    if (smartlyId.includes('th'))
      return 'thermostat';

    if (smartlyId.includes('hc'))
      return 'hotcloset';

    if (smartlyId.includes('lg'))
      return 'lighting';

    if (smartlyId.includes('sc'))
      return 'smartlycontrol'
  }

  /**
   * build path S3 for get json
   * @returns {String} `string`
   */
  function getPathS3() {
    const type = returnDeviceType();
    const deviceModel = returnDeviceModel();
    const deviceModelPath = returnDevicePath();

    return `https://smartly-fw.s3.amazonaws.com/${type}/${deviceModelPath}/${deviceModel}.json`;
  }

  /**
   * send job to iot core
   */
  async function sendJob() {

    if (!isDeviceAdd(deviceInfos))
      addDevicesToLocalStorage(deviceInfos.id);

    const lastSequence = await getNextJobSequence();
    const jobId = `${deviceGroup}-${params.get('username')}-${lastSequence}`;
    const path = getPathS3();
    const input = {
      jobId: jobId,
      targets: [`arn:aws:iot:us-east-1:589464758178:thing/${params.get('smartlyId')}`],
      documentSource: getPathS3(),
      abortConfig: {
        criteriaList: [{
          action: 'CANCEL',
          failureType: "FAILED" || "REJECTED" || "TIMED_OUT" || "ALL",
          thresholdPercentage: 10,
          minNumberOfExecutedThings: 1
        }]
      },
      timeoutConfig: {
        inProgressTimeoutInMinutes: 2
      }
    };

    const command = new CreateJobCommand(input);
    const iotClient = new IoTClient({
      region: 'us-east-1',
      credentials: {
        accessKeyId: awsExports.accessKeyId,
        secretAccessKey: awsExports.secretAccessKey
      }
    });

    try {
      const data = await iotClient.send(command);
      setUpdating(true);
      getStatusJob(jobId);
    } catch (error) {
      console.error('Error sending job command: ', error);
    }
  }

  /**
   * lista todos os jobs de acordo com o smartlyId (thingName)
   */
  async function listJobs() {
    const iotClient = new IoTClient({
      region: 'us-east-1',
      credentials: {
        accessKeyId: awsExports.accessKeyId,
        secretAccessKey: awsExports.secretAccessKey
      }
    });

    try {
      const smartlyId = params.get('smartlyId');
      const thingName = smartlyId;
      let idInterval = setInterval(async () => {
        try {
          const command = new ListJobExecutionsForThingCommand({ thingName });
          const jobThing = await iotClient.send(command);

          if (jobThing.executionSummaries && jobThing.executionSummaries.length > 0) {
            const jobExecutionSummary = jobThing.executionSummaries[0].jobExecutionSummary;

            if (jobExecutionSummary.status === "IN_PROGRESS") {
              setUpdating(true);
            } else {
              setUpdating(false);
              clearInterval(idInterval);
            }
          } else {
            setUpdating(false);
            clearInterval(idInterval);
          }
        } catch (error) {
          console.error('Error listing jobs:', error);
          setUpdating(false);
          clearInterval(idInterval);
        }
      }, 1000);
    } catch (error) {
      console.error('Error listing jobs: ', error);
    }
  }

  /**
   * adiciona um novo numero sequencial de acordo com o ultimo job coletado.
   */
  async function getNextJobSequence() {
    const iotClient = new IoTClient({
      region: 'us-east-1',
      credentials: {
        accessKeyId: awsExports.accessKeyId,
        secretAccessKey: awsExports.secretAccessKey
      }
    });

    try {
      const smartlyId = params.get('smartlyId');
      const thingName = smartlyId;
      const command = new ListJobExecutionsForThingCommand({ thingName });
      const response = await iotClient.send(command);

      const jobSummaries = response.executionSummaries || [];

      const sequenceNumbers = jobSummaries.map(summary => {
        const jobId = summary.jobId;

        if (!jobId) return 0;

        const parts = jobId.split('-');
        const lastPart = parts.pop();

        if (lastPart && /^\d{3}$/.test(lastPart)) {
          return parseInt(lastPart, 10);
        } else {
          return 0;
        }
      }).filter(num => num > 0);

      const nextSequence = sequenceNumbers.length > 0 ? Math.max(...sequenceNumbers) + 1 : 1;

      const formattedSequence = nextSequence.toString().padStart(3, '0');

      return formattedSequence;

    } catch (error) {
      console.error('Error getting next job sequence:', error);
      return '001'; // Valor padrão em caso de erro
    }
  }

  /**
   * verify version firmware installed in device
   */
  function verifyVersionFirmware() {
    const type = returnDeviceType();
    const model = returnDeviceModel();

    if (type === '' || model === '' || versionInstalledFirmware === undefined) {
      return
    }

    const url = getPathS3();

    getJSON(url,
      function (err, data) {
        if (err !== null || data === null) {
          console.error(err)
        } else {
          if (versionInstalledFirmware !== data.version) {
            setLatestVersion(data.version);
            setUpdatedFirmware(false);
          } else if (versionInstalledFirmware >= data.version) {
            setLatestVersion(data.version);
            setUpdatedFirmware(true);
          }
        }
      }
    )
  }

  /**
   * get json in aws S3
   * @param {String} url 
   * @param {Function} callback
   */
  async function getJSON(url, callback) {
    const xhr = new XMLHttpRequest();
    xhr.open('GET', url, true)
    xhr.responseType = 'json';
    xhr.onload = function () {
      var status = xhr.status;
      if (status === 200) {
        callback(null, xhr.response)
      } else {
        callback(status, xhr.response)
      }
    }
    xhr.send();
  }

  /**
   * @param {uuid} jobId 
   * 
   * coleta o status do job que enviamos através do sendJob
   */
  async function getStatusJob(jobId) {

    const iotClient = new IoTClient({
      region: 'us-east-1',
      credentials: {
        accessKeyId: awsExports.accessKeyId,
        secretAccessKey: awsExports.secretAccessKey
      }
    });

    let job;
    let idInterval = setInterval(async () => {
      try {
        const command = new DescribeJobCommand({ jobId: jobId });
        job = await iotClient.send(command);

        if (job.job.jobProcessDetails.numberOfSucceededThings > 0) {
          setMessageAlert('Firmware atualizado com sucesso');
          setSuccessAlert(true);
          setShowAlert(true);
          clearInterval(idInterval);
          setUpdating(false);
        }

        if (
          job.job.jobProcessDetails.numberOfTimedOutThings > 0 ||
          job.job.jobProcessDetails.numberOfCanceledThings > 0 ||
          job.job.jobProcessDetails.numberOfRejectedThings > 0 ||
          job.job.jobProcessDetails.numberOfFailedThings > 0 ||
          job.job.jobProcessDetails.numberOfRemovedThings > 0
        ) {
          setMessageAlert('Erro ao enviar atualização de firmware');
          setErrorAlert(true);
          setShowAlert(true);
          clearInterval(idInterval);
          setUpdating(false);
        }
      } catch (error) {
        console.error(error);
      }
    }, 1000);
  }

  function handleReboot() {
    try {
      setLoadingReboot(true)
      deviceInfos.reboot();
    } catch (e) {
      console.error(e);
    }
  }

  /**
   * manager configuration and send update 
   */
  async function handleThermostatUpdateConfig() {
    window.scrollTo(0, 0);

    if (!isDeviceAdd(deviceInfos))
      addDevicesToLocalStorage(deviceInfos.id)

    deviceInfos.updateSetpoint(modifySetPoint);
    const res = await deviceInfos.updateAllConfig(params.get('userId'), params.get('smartlyId'), {
      ledIntensity: ledIntensity,
      tempRange: [tempMin, tempMax],
      awayTemp: awayTemp,
      manual: prog ? 1 : 0
    })
    deviceInfos.updateConfig({
      power,
      gauging
    }
    )

    setShowAlert(true)

    if (res.status === 1)
      setSuccessAlert(true)
    else
      setErrorAlert(true)

    setMessageAlert(res.message)
  }

  /**
   * @params {}
  */

  async function getDeviceJobInfo() {
    listJobs()
  };

  async function handleHotClosetUpdateConfig() {
    window.scrollTo(0, 0);

    if (
      power === deviceInfos.device.power &&
      modifySetPoint === deviceInfos.device.setPoint
    ) {
      setMessageAlert('Nenhuma informação alterada');
      setErrorAlert(true);
      setShowAlert(true);
      return
    }

    if (!isDeviceAdd(deviceInfos))
      addDevicesToLocalStorage(deviceInfos.id)

    const oldInfos = {
      power: power,
      setPoint: deviceSetPoint
    }

    deviceInfos.updateConfig({ hum_setpoint: modifySetPoint, power: power });

    var count = 0;
    var idInterval = setInterval(async () => {

      if (
        oldInfos.power !== deviceInfos.device.power ||
        oldInfos.setPoint !== deviceInfos.device.setPoint
      ) {
        setMessageAlert('Dispositivo atualizado com sucesso');
        setSuccessAlert(true);
        setShowAlert(true)
        clearTimeout(idInterval);

      }
      if (count > 5) {
        setMessageAlert('Erro ao atualizar o dispositivo');
        setErrorAlert(true);
        setShowAlert(true);
        clearTimeout(idInterval);
      }
      count++
    }, 1000);
  };

  /**
   * get device uuid in DynamoDB 
   */
  async function getUUIDToApi() {
    try {
      const res = await getUUID(params.get('deviceId'), params.get('smartlyId'));
      if (res && res.data && res.data.body) {
        setUuid(res?.data?.body?.body);
      }
    } catch (error) {
      console.error(error)
    } finally {
      setLoadingQRCode(false);
    }
  };
  const copyToClipboard = () => {
    navigator.clipboard.writeText(uuid)
      .then(() => {
        setCopied(true)
      })
  };
  useEffect(() => {
    getDeviceJobInfo()
  }, [])

  useEffect(() => {
    getUUIDToApi()
  }, [])

  useEffect(() => {
    verifyVersionFirmware();
  }, [versionInstalledFirmware]);

  useEffect(() => {
    deviceWifi === "connected" ?
      setLoadingReboot(false) : setLoadingReboot(true)
  }, [deviceWifi])

  useEffect(() => {
    if (deviceInfos && deviceInfos.device && (deviceInfos.device["wifi-settings"] || deviceInfos.device.wifiSettings)) {
      if (deviceInfos.device.wifiSettings) {
        setWifiSettings(
          {
            ssid: deviceInfos.device.wifiSettings.ssid,
            password: deviceInfos.device.wifiSettings.password
          })
      } else {
        setWifiSettings(
          {
            ssid: deviceInfos.device["wifi-settings"].ssid,
            password: deviceInfos.device["wifi-settings"].password
          })
      }
    }
  }, [versionInstalledFirmware])

  return (
    <Col>
      <Card className={styles.card} style={isThermostat(params.get('smartlyId')) ? { height: '950px' } : {}}>
        <DeviceCardHeader deviceName={params.get('deviceName')} smartlyId={params.get('smartlyId')} />
        <Card.Body className={styles.cardBody} >
          <Row className={styles.cardBodyRow}>
            <Col xs={6} sm={4}>
              <Button
                className={[button.colorWhite, button.width100]}
                onClick={() => handleReboot()}
                disabled={loadingReboot}
              >
                {
                  deviceWifi === "disconnected" ? 'Desconectado' :
                    loadingReboot ?
                      <Spinner
                        style={{ height: "20px", width: "20px" }} animation="border" variant="light" /> : 'Reiniciar'
                }
              </Button>
            </Col>
            <Col xs={6} sm={3}>
              <Form.Switch
                className={styles.center}
                label={power ? 'ON' : 'OFF'}
                onChange={() => setPower(!power)}
                checked={Boolean(power)}
                data-testid='power'
              />
            </Col>
            <Col xs={6} sm={4}>
              <Button
                className={[button.colorWhite, button.width100]}
                onClick={() => setModalOpen(true)}
                disabled={loadingQRCode}
              >
                {loadingQRCode ?
                  <Spinner
                    style={{ height: "20px", width: "20px" }} animation="border" variant="light" /> : 'Gerar QR Code'}
              </Button>
            </Col>
          </Row>
          <Modal show={modalOpen}>
            <Modal.Header closeButton onClick={() => setModalOpen(false)} >
              <Modal.Title>QR Code</Modal.Title>
            </Modal.Header>
            <Modal.Body className="d-flex flex-column align-items-center">
              <QRCode id="QRCode" value={uuid} />
              <span className='mt-3'>{uuid}</span>
            </Modal.Body>
            <Modal.Footer>
              <Button className={button.colorWhite}
                onClick={copyToClipboard} disabled={copied} >
                {copied ? "Copiado" : <>Copiar UUID <FaRegCopy /></>}
              </Button>
              <Button className={button.colorWhite}
               onClick={handleQRcodeDownload}>Baixar QR Code <GetApp /></Button>
            </Modal.Footer>
          </Modal>
          {isThermostat(params.get('smartlyId')) ?
            <ThermostatCardBody />
            :
            <HotClosetCardBody
              deviceWifi={deviceWifi}
              deviceSetPoint={deviceSetPoint}
              modifySetPoint={modifySetPoint}
              setModifySetPoint={setModifySetPoint}
            />
          }
          <Row className={styles.center}>
            <Col sm={5} xs={7} className={styles.textAlignLeft}>
              {`Rede WiFi: ${wifiSettings.ssid}`}
            </Col>
            <Col sm={5} xs={5} className={styles.textAlignRight}>
              {`Senha: ${wifiSettings.password}`}
            </Col>
          </Row>
          <Row className={styles.center}>
            <Col sm={5} xs={7} className={styles.textAlignLeft}>Versão do Firmware: </Col>
            <Col sm={5} xs={5} className={styles.textAlignRight}> {versionInstalledFirmware} </Col>
          </Row>
          <Row className={styles.groupButtons}>
            <Col xs={12} sm={6}>
              <div className={styles.buttons}>
                <Button
                  style={{ width: "65%", backgroundColor: !updating ? Colors.secondaryLight : Colors.gray }}
                  className={!updating ? button.colorWhite : button.gray}
                  onClick={() => sendJob()}

                  disabled={updatedFirmware || updating || loadingReboot || deviceWifi === "disconnected"}
                >
                  {
                    loadingReboot || updating ? (
                      <Spinner style={{ height: "20px", width: "20px" }} animation="border" variant="light" />
                    ) : (
                      <>
                        {updatedFirmware ? 'Firmware atualizado' : 'Atualizar Firmware'}
                      </>
                    )}
                </Button>
              </div>
            </Col>
            <Col xs={12} sm={6}>
              <div className={styles.buttons}>
                <Button
                  className={button.colorWhite}
                  onClick={() => {
                    isThermostat(params.get('smartlyId')) ?
                      handleThermostatUpdateConfig() :
                      handleHotClosetUpdateConfig()
                  }}
                >
                  Salvar Configurações
                </Button>
              </div>
            </Col>
          </Row>
        </Card.Body>
      </Card>
    </Col>
  );
}

export default DeviceCardConfig;