import React, { useEffect, useState, useContext } from 'react';
import { 
  Form,
  Col,
  Row,
  Button,
} from 'react-bootstrap';
import { useSearchParams, useNavigate } from "react-router-dom"

import { API } from "service/Api";

import Thermostat from 'class/thermostat';
import Hotcloset from "class/Hotcloset";

import MiniThermostat from 'components/MiniThermostatCard';
import MiniHotCloset from 'components/MiniHotClosetCar';
import Authorization from "components/Authorization";
import AlertMessage from "components/AlertMessage";
import Local from 'components/Local';
import NotAuthorized from "components/NotAuthorized";

import { AlertContext } from "context/alert";
import { SupportContext } from 'context/support';

import button from 'assets/Button/button.module.scss';
import styles from "./style.module.scss";

/**
 * build user page
 * @returns {React.Component} `component`
 */
function User(props) {
  const { showAlert } = useContext(AlertContext);
  const { addUsersServedToLocalStorage, getUsersServed } = useContext(SupportContext);
  const [ params ] = useSearchParams();
  const navigate = useNavigate();
  const [locals, setLocals] = useState([]);
  const [devices, setDevices] = useState([]);
  const [local, setLocal] = useState("default");
  const [user, setUser] = useState(
    {
      username: "",
      email: "",
      name: ""
    }
  );
  const [authorizated, setAuthorizated] = useState(false);

  /**
   * get and set locals and devices in DB, based on username in query string params
   */
  async function getData(){
    try{
      const response = await API.get(
        "SmartlyAPI",
        `/locals`,
        {
          queryStringParameters:{
            username: user.username
          }
        }
      );

      const devices = await API.get(
        "SmartlyAPI",
        `/devices`,
        {
          queryStringParameters:{
            username: user.username
          }
        }
      );

      let temporaryDevice = [];
      if(devices){
        devices.map((value) => {
          var localDevice;
          isThermostat(value.smartlyId) ?
          localDevice = new Thermostat({id: value.smartlyId, name: value.environment, localName: value.localName, deviceId: value.id})
          :
          localDevice = new Hotcloset({id: value.smartlyId, name: value.environment, localName: value.localName, deviceId: value.id})
          temporaryDevice.push(localDevice);
          localDevice.subscribe(isThermostat(value.smartlyId) ? 'thermostat' : 'hotcloset');
        });
      }
      
      setDevices(temporaryDevice);
      setLocals(response);
    }catch(e){
      console.log(e);
    }
  }

  /**
   * Verify if device is thermostat
   * @param {String} id 
   * @returns {boolean} `bool`
   */
  function isThermostat(id){
    if(id.includes('th')) return true;

    return false
  }

  /**
   * attach the id local with the same local name
   * @param {Device} device 
   */
  function attachLocalsId(device){
      if(device && locals) {
        locals.map((local) => {
          if(device.localName === local.Name)
            device.local_id = local.Local_id
        })
      }
  }

  /**
   * get users when start attendance
   * @param {String} username 
   */
  function userExist(username){
    const users = getUsersServed()
    if(!users)
      return false

    const usersSplit = users.split(' ');

    const test = usersSplit.filter(user => user === username)

    if(test.length > 0)
      return true
    else 
      return false
  }

  function openForm(){
    window.open(`https://www.admin.smartlybrasil.com/main/form`, "assistenceWindow", "popup width=500 height=300");
    localStorage.setItem('assistanceStarted', true);
  }

  function openSupportLog() {
    window.open(`http://www.admin.smartlybrasil.com/main/supportlog?user=${user.username}`, "assistenceWindow", "popup width=900 height=700");
  }

  useEffect(() => {
    devices.map((device) => attachLocalsId(device))
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [locals])
  
  useEffect(() => {
    if(user.username){
      if(user.username.length){
        getData();
        if(!userExist(user.username)){
          addUsersServedToLocalStorage(user.username)
        }
        const assistanceStarted = localStorage.getItem('assistanceStarted');
        if (assistanceStarted === null){
          openForm();
        }
      }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  useEffect(() => {
    setUser(
      {
        username: params.get("username"),
        email: params.get("email"),
        name: params.get("name"),
        userId: params.get("userId")
      }
    );
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Authorization setAuthorizated={setAuthorizated}/>      
        {
        !authorizated ? 
          <NotAuthorized />
        :
          <>
            <div className={styles.body}>
              <Row className={styles.row}>
                <Col className={styles.back}>
                  <Button
                    className={button.colorWhite}
                    onClick={() => navigate("/main/users")}
                  >
                    Voltar
                  </Button>
                </Col>
                <Col className={styles.openForm}>
                  <Button
                    className={button.colorWhite}
                    onClick={() => openSupportLog()}
                  >
                    Ver Histórico
                  </Button>
                </Col>
              </Row>
              <Row className={styles.row}>
                <Col md={4} xs={12} className={styles.textUsername}>
                  {user.username}
                </Col>
                <Col md={4} xs={12} className={styles.textEmail}>
                  {user.email}
                </Col>
                <Col md={4} xs={12} className={styles.textName}>
                  {user.name}
                </Col>
              </Row>
              <Row className={styles.row}>
                <Col sm={3} xs={12}>
                  <Form.Select
                    value={local}
                    onChange={(select) => setLocal(select.target.value)}
                  >
                    <option value="default">Todos</option>
                    {
                      locals.length && locals.map((value) => 
                        <option key={value.Name} value={value.Name}>{value.Name}</option>)
                    }
                  </Form.Select>
                </Col>
                {local !== "default" && <Local locals={locals}/>}
              </Row>
            </div>
            { showAlert && <AlertMessage /> }
            <Row className={styles.showDevices}>
              {devices.map((device) => {          
                if(device.localName === local || local === "default")            
                  return isThermostat(device.id) ? 
                    <MiniThermostat key={device.id} device={device} user={user} /> : 
                    <MiniHotCloset key={device.id} device={device} user={user} />;
                })
              }
            </Row>
          </>
        }
    </>
  );
}

export default User;