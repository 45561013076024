import { Button, Container } from "react-bootstrap";
import { useNavigate } from "react-router-dom";

import { support } from "data/support";

import TableSupport from "components/TableSuport";

import styles from './Help.module.scss';
import button from 'assets/Button/button.module.scss';

/**
 * build the help page
 * @returns {React.Component}
 */
function Help() {
  const navigate = useNavigate();

  return ( 
    <Container className={styles.container}>
      <Button className={button.colorWhite} onClick={() => navigate(-1)}>Voltar</Button>
      <TableSupport support={support} />
      <section className={styles.linksVideos}>
        <p className={styles.videosName}>
          SmartLy APP Configuração Cenários 
          <a href="https://youtu.be/Wz5G041nwcQ" target="_blank" rel="noreferrer">Clique para assistir</a> 
        </p>
        <p className={styles.videosName}>
          Smartconfig - SL201/211/221 
          <a href="https://youtu.be/sId1vUbt3Wg" target="_blank" rel="noreferrer">Clique para assistir</a>
        </p>
        <p className={styles.videosName}>
          Smartconfig - SC104/SC109 
          <a href="https://youtu.be/9GVGdO6Zl_s" target="_blank" rel="noreferrer">Clique para assistir</a>
        </p>
        <p className={styles.videosName}>
          Configuração Rotina 
          <a href="https://youtu.be/e5uPr4VO_a8" target="_blank" rel="noreferrer">Clique para assistir</a>
        </p>
        <p className={styles.videosName}>
          Funcionalidades Básicas - HF918 
          <a href="https://youtu.be/XStjMPXpMGI" target="_blank" rel="noreferrer">Clique para assistir</a>
        </p>
        <p className={styles.videosName}>
          Funcionalidades Básicas - HF810/HF900 
          <a href="https://youtube.com/shorts/uZZVYe5ilVw" target="_blank" rel="noreferrer">Clique para assistir</a>
        </p>
        <p className={styles.videosName}>
          Funcionalidades Básicas - HF801 
          <a href="https://youtube.com/shorts/vNsATrQx5YM" target="_blank" rel="noreferrer">Clique para assistir</a>
        </p>
        <p className={styles.videosName}>
          Smartconfig - HF810/HF900 
          <a href="https://youtu.be/t0uWm7yx5So" target="_blank" rel="noreferrer">Clique para assistir</a>
        </p>
        <p className={styles.videosName}>
          Smartconfig - HFC405 
          <a href="https://youtu.be/QfikNM9Kfl4" target="_blank" rel="noreferrer">Clique para assistir</a>
        </p>
        <p className={styles.videosName}>
          Smartconfig - HF918 
          <a href="https://youtu.be/SlgFl7GOGDc" target="_blank" rel="noreferrer">Clique para assistir</a>
        </p>
      </section>
    </Container>
   );
}

export default Help;