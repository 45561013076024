import React, { useState } from 'react';

export const InfosDeviceContext = React.createContext();
InfosDeviceContext.displayName = 'Device Infos';

export const InfosDeviceProvider = ({ children }) => {
  const [power, setPower] = useState(false);
  const [prog, setProg] = useState(false);
  const [deviceSetPoint, setDeviceSetPoint] = useState(20);
  const [modifySetPoint, setModifySetPoint] = useState(20);
  const [tempMin, setTempMin] = useState(0);
  const [tempMax, setTempMax] = useState(0);
  const [ledIntensity, setLedIntensity] = useState(0);
  const [awayTemp, setAwayTemp] = useState(0);
  const [gauging, setGauging] = useState(0)
  const [deviceWifi, setDeviceWifi] = useState(-1);
  const [deviceTemp, setDeviceTemp] = useState(-1);
  const [deviceHum, setDeviceHum] = useState(0);
  const [deviceGroup, setDeviceGroup] = useState('');
  const [versionInstalledFirmware, setVersionInstalledFirmware] = useState('');
  const [creationDate, setCreationDate] = useState('');

  return (
    <InfosDeviceContext.Provider
      value={{
        power,
        setPower,
        prog,
        setProg,
        deviceSetPoint,
        setDeviceSetPoint,
        modifySetPoint,
        setModifySetPoint,
        tempMin,
        setTempMin,
        tempMax,
        setTempMax,
        ledIntensity,
        setLedIntensity,
        awayTemp,
        setAwayTemp,
        gauging,
        setGauging,
        deviceWifi,
        setDeviceWifi,
        deviceTemp,
        setDeviceTemp,
        deviceHum,
        setDeviceHum,
        deviceGroup,
        setDeviceGroup,
        versionInstalledFirmware,
        setVersionInstalledFirmware,
        creationDate,
        setCreationDate
      }}
    >
      {children}
    </InfosDeviceContext.Provider>
  )
}