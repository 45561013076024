import Amplify, { API, Auth } from "aws-amplify";

Amplify.configure({
  API: {
    endpoints: [
      {
        name: "SmartlyAPI",
        endpoint:
          "https://i6rv4nzukl.execute-api.us-east-1.amazonaws.com/smartly",
        custom_header: async () => {
          return {
            Authorization: `Bearer ${(await Auth.currentSession())
              .getIdToken()
              .getJwtToken()}`,
            "Content-Type": "application/json",
            Accept: "application/json",
            "Access-Control-Allow-Origin": "*",
          };
        },
      },
      {
        name: "GetJson",
        endpoint:
          "https://smartly-fw.s3.us-east-1.amazonaws.com",
        custom_header: async () => {
          return {
            "Content-Type": "application/json",
            Accept: "application/json",
            "Access-Control-Allow-Origin": "*",
          };
        },
      },
      {
        name: "SmartlyBrasilAPI",
        endpoint: "https://gwabnkvh6d.execute-api.us-east-1.amazonaws.com/smartlybrasil_dev",
        custom_header: async () => {
          return {
            Authorization: `Bearer ${(await Auth.currentSession()).getIdToken().getJwtToken()}`,
            Accept: "application/json",
            "Access-Control-Allow-Origin": "*",
          };
        },
      },
    ],
  },
});

export async function attachPolicy() {
  const id = await Auth.currentUserCredentials();
  const user = await Auth.currentUserInfo();
  const { identityId } = id;
  const response = await API.post(
    "SmartlyAPI",
    `/users/${user.username}/policy`,
    {
      response: true,
      body: {
        target: identityId,
      },
    }
  );
  return response;
}

export async function getUUID(deviceId, smartlyId){
  const response = await API.get(
    "SmartlyAPI",
    `/devices/${deviceId}/admin/uuid?smartlyId=${smartlyId}`,
    {
      response: true
    }
  );
  return response;
}

export async function getDeviceBySmartlyID(smartlyId){
  const response = await API.get(
    "SmartlyAPI",
    `/devices?smartlyId=${smartlyId}`,
    {
      response: true
    }
  )
  return response;
}

export async function deleteDevice(deviceId, userId){
  const response = await API.del(
    "SmartlyAPI",
    `/devices/${deviceId}`,
    {
      body: {
        userId: Number(userId)
      }      
    }
  )
  return response;
}

export { API };