import React from 'react';
import { Card } from 'react-bootstrap';
import styles from './DeviceCardHeader.module.scss';

/**
 * build the header device card
 * @returns {React.Component} `component`
 */
function DeviceCardHeader({ deviceName, smartlyId }) {
  return ( 
    <div className={styles.cardHeader} >
      <Card.Title className={styles.center}>
        Configurações 
      </Card.Title>
      <Card.Title className={styles.center}>
        {`${deviceName} - (${smartlyId}) `}
      </Card.Title>
    </div>
   );
}

export default DeviceCardHeader;