import React, { useState } from 'react'
import Authorization from "components/Authorization";
import NotAuthorized from "components/NotAuthorized";
import { authorization } from 'service/Authorizarion';

import styles from './SupportSchedule.module.scss';
import { Container } from 'react-bootstrap';
import SupportNavBar from '../SupportNavBar';

export default function SupportSchedule() {
  const [authorizated, setAuthorizated] = useState(authorization());

  return (
    <>
      <Authorization setAuthorizated={setAuthorizated} />
      {
        !authorizated ?
          <NotAuthorized />
        :
        <>
        <SupportNavBar/>
        <Container>
        <h2 className={styles.title}>
          Agenda
        </h2>
        <h1 style={{textAlign: "center"}}>
          Página em construção
        </h1>
        </Container>
        </>
      }
    </>
  )
}
