import { OutlinedInput } from "@material-ui/core";
import Logo from "assets/Images/smartly-logo.png";
import { useState } from "react";
import { Button, Col, Container, Form, Image, Row } from "react-bootstrap";

import styles from './ForgotPassword.module.scss';
import FormRequestCode from "components/FormRequestCode";
import FormChangePass from "components/FormChangePass";

export default function ForgotPassword(){
  const [codeRequested, setCodeRequested] = useState(false);
  const [username, setUsername] = useState('');
 
  return(
    <>
      <Container className={styles.container}>
        <Col sm={8} md={4} xs={10} className={styles.form}>
          <Image 
            src={Logo}
            fluid
          />
          { !codeRequested? 
            <FormRequestCode username={username} setUsername={setUsername} setCodeRequested={setCodeRequested} /> 
            :
            <FormChangePass username={username} setCodeRequested={setCodeRequested} /> }
      </Col>
      </Container>
    </>
  )
}