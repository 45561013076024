import { PubSub } from "aws-amplify";
import Device from "class/Device";

class Hotcloset extends Device {
  /**
   * send the update config device on MQTT topic
   * @param {Object} config
   */
  updateConfig(config){
    this.config = config;
    PubSub.publish(`$aws/things/${this.id}/shadow/name/config/update`, 
    {
      state: {
        desired: {
          config: this.config
        }
      }
    });
  }
}

export default Hotcloset;