import { useContext } from "react";
import SliderDevice from "../Slider";
import styles from './ThermostatCardBody.module.scss';
import { Row, Col } from 'react-bootstrap';
import { InfosDeviceContext } from "context/device";

/**
 * build thermostat card body
 * @returns {React.Component} `component` 
 */
function ThermostatCardBody() {
  const {
    deviceWifi,
    deviceTemp,
    deviceSetPoint,
    tempMin,
    setTempMin,
    tempMax,
    setTempMax,
    modifySetPoint,
    setModifySetPoint,
    ledIntensity,
    setLedIntensity,
    awayTemp,
    setAwayTemp,
    gauging,
    setGauging
  } = useContext(InfosDeviceContext)
  
  return ( 
  <>
    <Row sm={3} xs={12} className={styles.infos}>
      <Col>
        Wifi: { deviceWifi === "connected" ? 'Conectado' : 'Desconectado'}
      </Col>
      <Col>
        Temperatura: {deviceTemp}ºC
      </Col>
      <Col>
        Set-Point: {deviceSetPoint}ºC
      </Col>
    </Row>
    <SliderDevice 
      title={'Set-point'}
      isTemp={true}
      step={0.5}
      min={tempMin}
      max={tempMax}
      value={modifySetPoint}
      setValue={setModifySetPoint}
    />
    <SliderDevice 
      title={'Temperatura mínima'}
      isTemp={true}
      step={0.5}
      min={5}
      max={tempMax-5}
      value={tempMin}
      setValue={setTempMin}
    />
    <SliderDevice 
      title={'Temperatura máxima'}
      isTemp={true}
      step={0.5}
      min={tempMin+5}
      max={35}
      value={tempMax}
      setValue={setTempMax}
    />
    <SliderDevice 
      title={'Intensidade do Led'}
      isTemp={false}
      step={1}
      min={0}
      max={10}
      value={ledIntensity}
      setValue={setLedIntensity}
    />
    <SliderDevice 
      title={'Temperatura do modo viagem'}
      isTemp={true}
      step={0.5}
      min={tempMin}
      max={tempMax}
      value={awayTemp}
      setValue={setAwayTemp}
    />
    <SliderDevice 
      title={'Aferir temperatura'}
      isTemp={true}
      step={0.5}
      min={-5}
      max={5}
      value={gauging}
      setValue={setGauging}
    />
  </>
   );
}

export default ThermostatCardBody;