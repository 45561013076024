import React, { useEffect, useState, useContext } from 'react';

import { Button, Container, Row, Col, Modal } from 'react-bootstrap';
import { useNavigate, useSearchParams } from 'react-router-dom';

import Hotcloset from 'class/Hotcloset';
import Thermostat from 'class/thermostat';

import { API, deleteDevice } from 'service/Api';
import { getDeviceGroup } from 'service/Device';
import { getType, initialGauging } from 'utils/Device';
import { Auth } from 'aws-amplify';
import { authorization } from 'service/Authorizarion';

import CardRoutine from 'components/CardRoutine';
import DeviceCardConfig from 'components/DeviceCardConfig';
import AlertMessage from 'components/AlertMessage';
import Authorization from 'components/Authorization';
import NotAuthorized from 'components/NotAuthorized';
import LogCard from 'components/LogCard';

import { AlertContext } from 'context/alert';
import { InfosDeviceContext } from 'context/device';

import styles from './Config.module.scss';
import button from 'assets/Button/button.module.scss';

/** 
 * build the config page
 * @returns {React.Component}
 */
function DeviceConfig() {
  
  const navigate = useNavigate();
  const [ params ] = useSearchParams();
  const [device, setDevice] = useState();
  const { showAlert, setShowAlert, setSuccessAlert, setErrorAlert, setMessageAlert } = useContext(AlertContext)
  const { 
    setPower, 
    setProg,
    setDeviceSetPoint,
    setModifySetPoint,
    setTempMin,
    setTempMax,
    setLedIntensity,
    setAwayTemp,
    setGauging,
    setDeviceWifi,
    setDeviceTemp,
    setDeviceHum,
    setDeviceGroup,
    setVersionInstalledFirmware,
    setCreationDate
  } = useContext(InfosDeviceContext)

  const smartlyId = params.get('smartlyId');
  const deviceId = params.get('deviceId');
  const environment = params.get('environment');
  const localName = params.get('localName');
  const [authorizated, setAuthorizated] = useState(authorization());
  const [lastUpdateMetadata, setLastUpdateMetada] = useState(0);
  const [metadata, setMetadata] = useState()
  const [lastUpdateDate, setLastUpdateDate] = useState();
  const [modalOpen, setModalOpen] = useState(false);

  /**
   * get the device data registered in API
   */
  async function getData(){    
    try {
      const deviceParams = (await API.get(
        "SmartlyAPI",
        '/devices',
        {
          queryStringParameters: {
            smartlyId: smartlyId
          }
        }
      ))
      let localDevice = [];
      isThermostat(deviceParams[0].smartlyId) ? 
      localDevice = new Thermostat({id: smartlyId, name: environment, localName: localName, deviceId: deviceId})
      :
      localDevice = new Hotcloset({id: smartlyId, name: environment, localName: localName, deviceId: deviceId})
      
      localDevice.subscribe(isThermostat(deviceParams[0].smartlyId) ? 'thermostat' : 'hotcloset');
      setDevice(localDevice);   
      
    } catch (error) {
      console.log(error)
    }
  }

  /**
   * create configuration to get all device config  
   * @param { Device | callback} res 
   */
  function startConfig(res){
    setDeviceWifi(res.status);
    setVersionInstalledFirmware(res?.fw_version);
    setCreationDate(res.config?.creationDate)
    setMetadata(device.metadata)
    
    if(isThermostat(params.get('smartlyId'))){
      if(Object.keys(res.config).length > 0){
        setTempMin(res.config.tempRange[0])
        setTempMax(res.config.tempRange[1])
        setLedIntensity(res.config.ledIntensity);
        setAwayTemp(res.config.awayTemp)
      }
      
      setModifySetPoint(res.setPoint);
      setDeviceSetPoint(typeof res.setPoint !== "undefined" ? res.setPoint : -1 );
      setGauging(initialGauging(res, res.temp));
      setDeviceTemp(res.temp);
      setPower(res.power);
      setProg(res.prog)

    }else{
      setGauging(0);
      setDeviceHum(res.hum)
      setDeviceSetPoint(typeof res.hum_setpoint !== "undefined" ? res.hum_setpoint : -1 );
      setPower(res.power);
      setModifySetPoint(res.hum_setpoint)
    }
  }

  /**
   * verify if is device thermostat
   * @param {String} id 
   * @returns {boolean}
   */
  function isThermostat(id){
    if(id.includes('th')) {
      return true
    }

    return false;
  }

  /**
   * when starting page, get data device
   */
  useEffect(() => {
    getData()
  }, [])

  /**
   * when variable device was modified, subscribe in MQTT topic to get e set device infos
   */
  useEffect(()=> {
    async function getGroupAsync(){
      setDeviceGroup(await getDeviceGroup(params.get('smartlyId')));
    }

    if(typeof device !== 'undefined'){
      device.subscribe(getType(smartlyId))
      getGroupAsync();

      if(isThermostat(params.get('smartlyId'))){
        device.getConfig();      
      }
      
      startConfig(device)
      
      device.callback((status)=> {
        startConfig(status);
      })
    }
  }, [device])

  /**
   * when variable metadata was modified, verify last update
   */
  useEffect(() => {
    if(typeof metadata !== 'undefined'){

      const itens = Object.values(metadata)
      
      let lastTime = itens[0].timestamp;
      itens.map((item) => {
        if( item.timestamp> lastTime){
          lastTime = item.timestamp
        }})
        
      setLastUpdateMetada(lastTime)
    }

  }, [metadata])
  
  async function handleDelete(){
    const user = await Auth.currentUserInfo();
    if(user?.attributes['custom:permission'] !== 'admin'){
      setShowAlert(true);
      setErrorAlert(true);
      setModalOpen(false);
      setMessageAlert('Você não tem permissão para deletar o dispositivo');
    }
    try {      
      const res = await deleteDevice(params.get('deviceId'), params.get('userId'))
      setShowAlert(true);
      setSuccessAlert(true);
      setMessageAlert(res.message);
      setModalOpen(false);
    
      navigate(-1);
    } catch (error) {
      setModalOpen(false);
      setShowAlert(true);
      setErrorAlert(true);
      setErrorAlert('Erro ao deletar o dispositivo');
    }
  }

  /**
   * when variable metadata was modified, set last update date
   */
  useEffect(() => {
    if(lastUpdateMetadata != 0){
      var date = new Date(lastUpdateMetadata * 1000);

      var stringDate = `${date.getDate()}/${date.getMonth()+1}/${date.getFullYear()} 
        - ${String(date.getHours()).padStart(2,"0")}:${String(date.getMinutes()).padStart(2,"0")}:${String(date.getSeconds()).padStart(2,"0")} `;

      setLastUpdateDate(stringDate)
    }
  }, [lastUpdateMetadata])
  
  return ( 
    <>
      <Authorization setAuthorizated={setAuthorizated} />
      { !authorizated? 
        <NotAuthorized />
        :      
        <Container className={styles.container}>
          <Button className={button.colorWhite} onClick={() => navigate(-1)} >Voltar</Button>
          <Row className={styles.clientInfos}>
            <Col sm={3} xs={12}>
              <Row className={styles.centerText}>Nome:</Row>
              <Row className={styles.centerText}>{params.get('username')}</Row>
            </Col>
            <Col sm={3} xs={12}>
              <Row className={styles.centerText}>E-mail:</Row>
              <Row className={styles.centerText}>{params.get('email')}</Row>
            </Col>
            <Col sm={3} xs={12}>
              <Row className={styles.centerText}>Nome completo</Row>
              <Row className={styles.centerText}>{params.get('name')}</Row>
            </Col>
          </Row>
          { showAlert && <AlertMessage /> }
          <Row>
            <Col className={styles.lastUpdate} md={8}>
              <p>
                Ultima atualização de status: {lastUpdateDate}
              </p>
            </Col>
            <Col className={styles.colDelete}>
              <Button variant='danger' onClick={() => setModalOpen(true)}>Deletar Dispositivo</Button>
            </Col>
          </Row>
          <Row className={styles.alertMessage}>
            <Col md={6} xs={12}>
              <DeviceCardConfig deviceInfos={device}/>
            </Col>
            {isThermostat(smartlyId) && 
              <Col md={6} xs={12}>
                <CardRoutine deviceId={deviceId} device={device}/> 
              </Col>
            }
            <LogCard device={device}/> 
          </Row>
          <Modal show={modalOpen} className={styles.modal} centered>
            <Modal.Header>
              <Col xs={11}>
                <Modal.Title>Deletar dispositivo</Modal.Title>
              </Col>
              <Col 
                className={styles.closeModal}
                xs={1} 
                onClick={() => setModalOpen(false)}
              >
                <span className="material-symbols-outlined">close</span>
              </Col>
            </Modal.Header>
            <Modal.Body className={styles.modalBody}>
              <p>
                Esta ação não poderá ser desfeita, todas as informações serão perdidas
              </p>
              <p>
                Tem certeza que deseja deletar o dispositivo?
              </p>
            </Modal.Body>
            <Modal.Footer className={styles.modalFooter}>
              <Button className={button.colorWhite} onClick={() => setModalOpen(false)}>
                Cancelar
              </Button>
              <Button variant='danger' onClick={() => handleDelete()}>
                Deletar
              </Button>
            </Modal.Footer>
          </Modal>
        </Container>
      }
    </>
   );
}

export default DeviceConfig;