import React, { useState } from "react";
import { Container, Nav, Navbar } from "react-bootstrap";
import { NavLink } from "react-router-dom";

import styles from './SupportNavBar.module.scss';

export default function SupportNavBar() {
  const [options] = useState([
    {
      title: "Agenda",
      path: "/main/support/schedule"
    },
    {
      title: "Suporte ao técnico",
      path: "/main/support/technician"
    },
    {
      title: "Suporte ao cliente",
      path: "/main/support/client"
    }
  ])

  return (
    <>
      <Navbar
      className={styles.headerContent}
      fixed="top"
      expand="lg"
      >
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse style={{justifyContent: "center"}} id="basic-navbar-nav">
          <Nav className={styles.navContainer}>
          {
          options.map((item, index) => 
            <NavLink
            key={index}
            className={({isActive}) =>
              isActive ? styles.navlinkActive : styles.navlink
            }
            to={item.path}
            >
            {item.title}
            </NavLink>
          )
          }
          </Nav>
        </Navbar.Collapse>
      </Navbar>
    </>
  )
}
