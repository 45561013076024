import { API } from 'service/Api';
import { PubSub } from "aws-amplify";
import { subscribe } from "service/Mqtt";

class Device {
  constructor({ id, name, localName, deviceId}){
    this.id = id;
    this.name = name;
    this.localName = localName;
    this.deviceId = deviceId;

    this.sub = {unsubscribe: () => console.log("Dont subscribe!")};
    this.device = {};
    this.config = {};

    this.func = (status) => {};
  }

  unsubscribe(){
    this.sub.unsubscribe();
  }

  callback(func){
    this.func = func;
  }

  /**
   * @param {Number} localId 
   * @returns {Device.subtype} device subtipe
   */
  async getSubType(localId) {
    var locals = []
    try {
      locals = (await API.get("SmartlyAPI", `/locals/${localId}/admin/devices`, { 
      }))
      var local;
      locals.filter((l) => {
        if(Number(this.deviceId) === l.deviceId)
          local = l;
        }
      )
      return local.subtype
    } catch (error) {
      console.log(error);
    }
  }

  /**
   * subscribe in MQTT topic
   * @param {String} deviceType
   */
  subscribe(deviceType){
    const subGet = subscribe(`$aws/things/${this.id}/shadow/name/${deviceType}/get/accepted`, 
      (data) => {
        this.sub = subscribe(`$aws/things/${this.id}/shadow/name/${deviceType}/update/delta`, 
          (data) => {
            this.device = {...this.device, ...data.value.state};
            this.func({...this.device, config: this.config});
            PubSub.publish(`$aws/things/${this.id}/shadow/name/${deviceType}/update`, {
              state: { reported: data.value.state }
            }); 
          }
        );
        PubSub.publish(`$aws/things/${this.id}/shadow/name/${deviceType}/update`, {
          state: { reported: data.value.state.desired }
        })
        subGet.unsubscribe();
        this.metadata = {...data.value.metadata.desired};
        this.device = {...data.value.state.desired};
        this.func({...this.device, config: this.config});
      }
    );
    setTimeout(() => {
      PubSub.publish(`$aws/things/${this.id}/shadow/name/${deviceType}/get`, {}); 
      setTimeout(() => {
        subGet.unsubscribe();
      }, 3000);
    }, 2000);
  }

}

export default Device