import { Col, Container, Form, Image } from "react-bootstrap";
import Logo from "../../../assets/Images/smartly-logo.png";
import Colors from "../../../assets/Colors";
import { useNavigate } from "react-router-dom";
import styles from '../AccessDenied.module.scss';

export default function NotExists(){

  const navigate = useNavigate();

  function handleSubmit(event){
    event.preventDefault();
    navigate("/login", {replace: true});
  }

  return (
    <Form onSubmit={handleSubmit}>
      <Container className={styles.notexistsContent}>
        <Col sm={4} >
          <Image src={Logo} fluid/>
          <Form.Text>
            Acesso negado!
            Volte a página inicial!
          </Form.Text>
          <Form.Control
            style={{
              backgroundColor: Colors.secondary,
              color: Colors.white
            }}
            type="submit" 
            value="Ir para página inicial"
          />
        </Col>
      </Container>
    </Form>
  );
}