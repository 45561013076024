import React, { useState } from "react";
import  awsExports  from "aws-exports";
import Amplify from "aws-amplify";
import { Routes, Route, Navigate } from "react-router-dom";
import { ProtectPage, NavBar } from "components";
import { SignIn } from "pages/Auth";
import { MainMenu, SearchDevice, SearchUser, User, DeviceConfig, RegisterUser } from "pages/Main";
import { NotExists, AccessDenied } from "pages/AccessDenied";
import { AlertProvider } from "context/alert";
import Devices from "context/devices";
import Help from "pages/Main/Help";
import SupportLog from "pages/Main/SupportLog";
import { SupportProvider } from "context/support";
import { InfosDeviceProvider } from "context/device";
import ForgotPassword from "pages/Auth/ForgotPassword";
import { SupportSchedule, SupportTechnician, SupportClient, InsertCall, EditCall } from "components/Support/index";

Amplify.configure(awsExports);

function App() {

  const [devices, setDevices] = useState([]);

  function addDevice(device) {
    setDevices((localDevices) => [...localDevices, device]);
  }

  function removeDevice(device) {
    device.sub.unsubscribe();
    setDevices((localDevices) => localDevices.filter((item) => item.id !== device.id));
  }

  function updateDevice(data, deviceId) {
    function getDevice(localDevices) {
      const is = localDevices.filter(
        (value) => {
          return value.id === deviceId;
        }
      );
      if (is.length) {
        const index = is[0].index;
        localDevices[index].state = { ...localDevices[index].state, ...data.value.state }
      }
      return [...localDevices];
    }
    setDevices((localDevices) => getDevice(localDevices));
  }

  return (
    <Devices.Provider
      value={
        {
          device: devices,
          addDevice,
          removeDevice,
          updateDevice
        }
      }
    >
      <AlertProvider>
        <InfosDeviceProvider>
          <SupportProvider>
            <Routes>
              <Route path="/" element={<Navigate to="/login" replace />} />
              <Route path="/login" element={<SignIn />} />
              <Route path="/forgotPassword" element={<ForgotPassword />} />
              <Route
                path="/main"
                element={
                  <ProtectPage
                    errorPage={<AccessDenied />}
                    targetPage={
                      <NavBar />
                    }
                  />
                }
              >
                <Route index element={<MainMenu />} />
                <Route
                  path="searchDevice"
                  element={<SearchDevice />}
                />
                <Route
                  path="users"
                  element={<SearchUser />}
                />
                <Route
                  path="users/user"
                  element={<User />}
                />
                <Route
                  path="users/user/device"
                  element={<DeviceConfig />}
                />
                <Route
                  path="register"
                  element={<RegisterUser />}
                />
                <Route
                  path="support"
                  element={<SupportClient />}

                />
                <Route
                  path="support/schedule"
                  element={<SupportSchedule />}
                />
                <Route
                  path="support/technician"
                  element={<SupportTechnician />}
                />
                <Route
                  path="support/client"
                  element={<SupportClient />}
                />
                <Route
                  path="support/client/insert"
                  element={<InsertCall />}
                />
                <Route
                  path="support/client/edit"
                  element={<EditCall />}
                />
                <Route
                  path="help"
                  element={<Help />}
                />
              </Route>
              <Route
                path="/main/supportlog"
                element={<SupportLog />}
              />
              <Route
                path="*"
                element={<NotExists />}
              />
            </Routes>
          </SupportProvider>
        </InfosDeviceProvider>
      </AlertProvider>
    </Devices.Provider>
  );
}

export default App;
