import { useContext, useState } from "react";
import SliderDevice from "../Slider";
import { Row, Col } from 'react-bootstrap';
import { InfosDeviceContext } from "context/device";
import styles from './HotClosetCardBody.module.scss';

/**
 * build the hotcloset card body
 * @returns {React.Component} `component`
 */
function HotClosetCardBody() {  
  const {
    deviceWifi, 
    deviceHum, 
    deviceSetPoint,
    modifySetPoint,
    setModifySetPoint
  } = useContext(InfosDeviceContext);

  return ( 
    <>
      <Row sm={3} xs={12} className={styles.infos}>
        <Col>
          Wifi: { deviceWifi === "connected" ? 'Conectado' : 'Desconectado'}
        </Col>
        <Col>
          Humidade: {deviceHum}%
        </Col>
        <Col>
          Set-Point: {deviceSetPoint}%
        </Col>
      </Row>
      <SliderDevice
        title={'Set-point'}
        isTemp={false}
        step={5}
        min={0}
        max={100}
        value={modifySetPoint}
        setValue={setModifySetPoint}
      />
    </>
  );
}

export default HotClosetCardBody;