import Slider from 'rc-slider';
import { Row, Col } from 'react-bootstrap';
import styles from './SliderDevice.module.scss';

/**
 * build slider standard
 * @returns {React.Component} `component`
 */
function SliderDevice({title, step, value, min, max, setValue, isTemp}) {
  return (
    <Row className={styles.standardSlider}>
      <Col sm={10} xs={12}>
      <Row className={styles.standardSlider}>
        <Col className={styles.colLeft}>
          {title}
        </Col>
        <Col className={styles.colRight}>
          {isTemp? isNaN(value) ? '-' : `${Number(value).toFixed(1)}ºC` : value}
        </Col>
      </Row>
      <Row sm={1} xs={12}>
        <div className={styles.standardSlider}>
          <Slider
            step={step}
            min={min}
            max={max}
            value={value}
            onChange={(e) => {setValue(e)}}
            />
        </div>
      </Row>
      </Col>
    </Row>

   );
}

export default SliderDevice;