import { AlertContext } from "context/alert";
import { useContext } from "react";
import { Alert } from "react-bootstrap";
import {  Row, Col } from 'react-bootstrap';
import styles from './AlertJob.module.scss';

/**
 * build alert component 
 * @returns {React.Component} `component` 
 */
function AlertMessage() {
  const { errorAlert, successAlert, setShowAlert, setErrorAlert, setSuccessAlert, messageAlert, setMessageAlert } = useContext(AlertContext);

  setTimeout(() => {
    clearAlert()
  },5000)

  function clearAlert(){
    setShowAlert(false);
    setErrorAlert(false);
    setSuccessAlert(false);
    setMessageAlert('');
  }

  return(    
    <Row sm={12} xs={12} className={styles.alert}>
      <Col sm={12} xs={12}>
        <Alert variant={errorAlert? 'danger' : successAlert? 'success' : '' } onClose={() => clearAlert() } dismissible>
          <p>
            {messageAlert}
          </p>
        </Alert>    
      </Col>
    </Row>
  )
}

export default AlertMessage;