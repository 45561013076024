/**
 * 
 * @param {String} id 
 * @returns {String} `device type` 
 */
export function getType(id){
  if(id.includes('th'))
    return 'thermostat'

  if(id.includes('hc'))
    return 'hotcloset'  
}

/**
 * verify if device id is add to local storage  
 * @returns {Boolean} `bool`
 */
export function isDeviceAdd(deviceInfos){
  const devices = localStorage.getItem('devices');
  
  if(devices){
    const splitDevices = devices.split(' ');
    var res;
    splitDevices.forEach((device) => {if(device === deviceInfos.id) res = device})
    if(res)
      return true
  }
  return false
}

/**
 * Get the device gauging initial based in floor temperature or enviroment temperature
 * @param {Number} gaug 
 * @returns {boolean} device gauging
 */
  export function initialGauging(state, gaug){
    if(!state) return
    const env = state.operation ? state.env_floor : state.env_temp;
    if((gaug-env) < 0.5 && (env-gaug) < 0.5){
      return 0;
    }
    return Number.parseFloat((Math.round((gaug-env)*2)/2).toFixed(1));
}