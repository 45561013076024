import React, { useState } from 'react';
import { Button, Row } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';

import { CardRegister } from 'components/index';
import Authorization from 'components/Authorization';
import NotAuthorized from 'components/NotAuthorized';
import { authorization } from 'service/Authorizarion';

import styles from './Register.module.scss';
import button from 'assets/Button/button.module.scss';

/**
 * build the register page
 * @returns {React.Component} `component`
 */
function Register() {
  const navigate = useNavigate()
  const [authorizated, setAuthorizated] = useState(authorization());

  return (  
    <>
      <Authorization setAuthorizated={setAuthorizated} />
      {!authorizated? 
      <NotAuthorized /> 
      :
      <>
        <Button 
          className={button.colorWhite} 
          style={{color:'white'}}
          onClick={() => navigate(-1)}
        >
          Voltar
        </Button>
        <Row className={styles.rowCard}>
          <CardRegister />
        </Row>
      </>
      }
    </>    
    );
}

export default Register;