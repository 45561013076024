import { PubSub } from "aws-amplify";
import { API } from "service/Api";
import Device from "class/Device";
import { getAccount } from 'service/Authorizarion';
import awsExports from 'aws-exports.js';

/**
 * Thermostat class extended Device class
 */
class Thermostat extends Device {
  /**
   * get the device config
   */
  async getConfig(){
    try{
      const url = `/devices/${this.deviceId}/admin/config?smartlyID=${this.id}`;
      this.config = (await API.get("SmartlyAPI", url, {
        response: true,
      })).data.parameters.config;
      this.func({...this.device, config: this.config});
    }catch(e){
      console.log(e);
    }
  }

  /**
   * send update power and gauging 
   * @param {Object} config
   */
  updateConfig(config){
    PubSub.publish(`$aws/things/${this.id}/shadow/name/config/update`, 
    {
      state: {
        desired: {
          config: {
            power: config.power,
            gauging: config.gauging
          }
        }
      }
    });
  }

  /**
   * update away temperature, led intensity, temp range, gauging and mode manual
   * @param {Number} userId 
   * @param {String} smartlyId 
   * @param {Object} config 
   * @returns return success or error to update in API
   */
  async updateAllConfig(userId, smartlyId, config){
    try {      
      const res = await API.post("SmartlyAPI", `/devices/${this.deviceId}/admin/config`,{
        body: { 
          userId,
          smartlyId,
          parameters:{
            config: {
              awayTemp: config.awayTemp,
              ledIntensity: config.ledIntensity,
              tempRange:[config.tempRange[0], config.tempRange[1]],
              gauging: config.gauging,
              manual: config.manual
            }
          }
        }
      })
      return res
    } catch (error) {
      return error
    }
  }

  /**
   * update set-point device
   * @param {Number} setpoint
   */
  updateSetpoint(setpoint){
    getAccount().then((value) => {
      PubSub.publish(`${this.id}/temperature`, {manualTemp: setpoint, source: `web-wa:device=(browser);account=${value}`});
    })
  }

  /**
   * reboot device
  */
  reboot(){
    PubSub.publish(`${this.id}/reboot`, {reboot: 1});
  }
}

export default Thermostat;