import MainMenuImport from "./MainMenu";
import SearchDeviceImport from "./SearchDevice";
import SearchUserImport from "./SearchUser";
import UserImport from "./User"
import DeviceConfigImport from "./Config";
import RegisterUserImport from "./Register";

export const SearchDevice = SearchDeviceImport;
export const MainMenu = MainMenuImport;
export const SearchUser = SearchUserImport;
export const User = UserImport;
export const DeviceConfig = DeviceConfigImport; 
export const RegisterUser = RegisterUserImport;
