/**
 * split string 
 * @param {String} testString 
 * @returns 
 */
export function splitString(testString){
  const testArray = String(testString).split('');

  const result = testArray.map((char) => {
    if(char === char.toUpperCase() && isNaN(char)){
      return 'upercase'
    };
    if(char === char.toLocaleLowerCase() && isNaN(char)){
      return 'lowercase'
    }
    if(!isNaN(char)){
      return 'number'
    }
  })
  return result;
}

/**
 * verify if string has a number character
 * @param {String} testString 
 * @returns {boolean}
 */
export function hasNumber(testString){
  const result = splitString(testString);
  if(result.indexOf('number') >=0 ) 
    return true

  return false
}

/**
 * verify if string has a lower case character
 * @param {String} testString 
 * @returns {boolean}
 */
export function hasLowercase(testString){
  const result = splitString(testString);
  if(result.indexOf('lowercase') >= 0) 
    return true

  return false
}

/**
 * verify if string has a Uppercase character
 * @param {String} testString 
 * @returns {boolean}
 */
export function hasUppercase(testString){
  const result = splitString(testString);
  if(result.indexOf('upercase') >= 0) 
    return true

  return false
}