import React, { useContext, useEffect, useState } from 'react';
import { Card, CloseButton, Col, Row, Button, Form, Modal, Spinner, Container } from "react-bootstrap";
import {
  SignalWifiOffSharp,
  SignalWifi1BarSharp,
  SignalWifi2BarSharp,
  SignalWifi3BarSharp,
  SignalWifi4BarSharp,
  ExpandMoreSharp,
  ExpandLessSharp,
  GetApp
} from '@material-ui/icons';
import { FaRegCopy } from "react-icons/fa";
import Colors from "assets/Colors";
import Slider from "rc-slider";
import Devices from "context/devices";
import QRCode from 'react-qr-code';

import { publish } from 'service/Mqtt';
import { API } from "service/Api";
import { initialGauging, isDeviceAdd } from 'utils/Device';
import { getDeviceUserAndRegDate } from 'service/Device';
import { SupportContext } from 'context/support';

import "rc-slider/assets/index.css"
import styles from '../DeviceCard.module.scss';
import button from 'assets/Button/button.module.scss';
import { getUUID } from 'service/Api';
import { getAccount } from 'service/Authorizarion'
import Routine from 'components/Routine';
import ThermostatClass from 'class/thermostat';

/**
 * build the thermostat card
 * @returns {React.Component} `component` 
 */
export default function Thermostat({ index, value }) {

  const { device, removeDevice } = useContext(Devices)
  const [config, setConfig] = useState('');
  const [awayTemp, setAwayTemp] = useState(config?.awayTemp);
  const [ledIntensity, setLedIntensity] = useState(value.state.ldr);
  const [tempRange, setTempRange] = useState(0)
  const [gauging, setGauging] = useState(initialGauging(value.state, value.state.temp));
  const [prog, setProg] = useState(value.state.prog);
  const [user, setUser] = useState('');
  const [regDate, setRegDate] = useState('');
  const [setPoint, updateSetPoint] = useState(value.state.setPoint);
  const [power, setPower] = useState(value.state.power);
  const [showGaug, setShowGaug] = useState(false);
  const [qrModalOpen, setQrModalOpen] = useState(false);
  const [routineModalOpen, setRoutineModalOpen] = useState(false);
  const [uuid, setUuid] = useState('');
  const [loadingQRCode, setLoadingQRCode] = useState(true);
  const [account, setAccount] = useState("none");
  const [routines, setRoutines] = useState({});
  const [Device, setDevice] = useState();
  const [devicesUser, setDevicesUser] = useState([]);
  const { addDevicesToLocalStorage } = useContext(SupportContext);
  const [copied, setCopied] = useState(false)

  const daysOfWeek = ['mon', 'tue', 'wed', 'thu', 'fri', 'sat', 'sun'];

  const handleQRcodeDownload = () => {
    const svg = document.getElementById("QRCode");
    const svgData = new XMLSerializer().serializeToString(svg);
    const canvas = document.createElement("canvas");
    const ctx = canvas.getContext("2d");
    const img = new Image();
    img.onload = () => {
      canvas.width = img.width;
      canvas.height = img.height;
      ctx.drawImage(img, 0, 0);
      const pngFile = canvas.toDataURL("image/png");
      const downloadLink = document.createElement("a");
      downloadLink.download = value.id;
      downloadLink.href = `${pngFile}`;
      downloadLink.click();
    };
    img.src = `data:image/svg+xml;base64,${btoa(svgData)}`;
  };

  const copyToClipboard = () => {
    navigator.clipboard.writeText(uuid)
      .then(() => {
        setCopied(true)
      })
  };

  function returnDayInPortuguese(day) {
    switch (day) {
      case 'mon':
        return 'Segunda-Feira';
      case 'tue':
        return 'Terça-Feira';
      case 'wed':
        return 'Quarta-Feira';
      case 'thu':
        return 'Quinta-Feira';
      case 'fri':
        return 'Sexta-Feira';
      case 'sat':
        return 'Sábado';
      case 'sun':
        return 'Domingo';
      default:
        return '';
    }
  }

  function filterRoutine(day) {
    return routines[day].h.map((value, index) => [value, routines[day].t[index]]);
  }

  function buildRoutine(day, i) {
    return filterRoutine(day).map((value, index) =>
      <Routine key={index + String(i)} time={value[0]} temp={value[1]} />
    )
  }

  /**
   * @param {String} status 
   * @param {Number} wifi 
   * @returns {React.Component} receive the status and wifi e build icon wifi
  */
  function getWiFiConnection(status, wifi) {
    if (status === "connected") {
      if (wifi === 4) {
        return <SignalWifi4BarSharp
          htmlColor={Colors.secondary}
          size={10}
        />
      } else if (wifi === 3) {
        return <SignalWifi3BarSharp
          htmlColor={Colors.secondary}
          size={10}
        />
      } else if (wifi === 2) {
        return <SignalWifi2BarSharp
          htmlColor={Colors.secondary}
          size={10}
        />
      } else if (wifi === 1) {
        return <SignalWifi1BarSharp
          htmlColor={Colors.secondary}
          size={10}
        />
      }
    } else
      return <SignalWifiOffSharp
        htmlColor={Colors.gray}
        size={10}
      />;
  }

  /**
   * send state in AWS
   */
  function sendState() {
    publish(`$aws/things/${value.id}/shadow/name/config/update`, {
      state: { desired: { config: { power: Number(power), gauging } } }
    });
    publish(`${value.id}/temperature`, {
      manualTemp: Number.parseInt(setPoint),
      source: `web-wa:device=(browser);account=${account};`
    });
  }

  /**
   * modify useState show gauging
   */
  function handleShowGaug() {
    setShowGaug(!showGaug);
  }

  async function getData() {
    let localDevice = [];
    localDevice = new ThermostatClass({ id: value.id, name: null, localName: null, deviceId: value.deviceId })
    localDevice.subscribe('thermostat');
    localDevice.callback((value) => setConfig(value.config));
    localDevice.getConfig();
    setDevice(localDevice);
  }

  async function updateConfig({ device, userId, smartlyId }) {
    try {
      if (!isDeviceAdd(device))
        addDevicesToLocalStorage(device.id)

      const config = {
        awayTemp: awayTemp,
        ledIntensity: ledIntensity,
        tempRange: tempRange,
        gauging: gauging,
        manual: prog ? 1 : 0,
      }

      await device.updateAllConfig(userId, smartlyId, config)
    }
    catch (e) {
      console.log("ERRO:", e)
    }
  }

  /**
   * Request getUUID function and then set uuid and loading qr code
  */
  async function getUUIDToApi() {
    const res = await getUUID(value.deviceId, value.id)
    setUuid(res.data.body.body);
    setLoadingQRCode(false)
  }

  async function handleSearch() {
    try {

      if (user.length < 1) {
        throw Error("user length < 1");
      }

      const response = await API.get(
        "SmartlyAPI",
        `/users/`,
        {
          queryStringParameters: {
            username: user,
            search: "username"
          }
        }
      );
      setDevicesUser(response);
    } catch (e) {
      console.log(e);
    }
  }

  useEffect(() => {
    setPower(value.state.power);
  }, [device]);

  useEffect(() => {
    let tempRange = config?.tempRange;
    setTempRange(tempRange);
    setAwayTemp(config?.awayTemp);
  }, [config])

  useEffect(() => {
    async function getInfos() {
      const response = await getDeviceUserAndRegDate(value.id);

      if (Object.keys(response).length > 0) {
        setUser(response[0].username);
        var date = new Date(response[0].regDate);
        setRegDate(`${date.getDate()}/${date.getMonth() + 1}/${date.getFullYear()}`);
      }
    }
    getInfos();
  }, [value.id]);

  useEffect(() => {
    handleSearch();
  }, [user]);

  useEffect(() => {
    setProg(value.state.prog);
    setLedIntensity(value.state.ldr);
    updateSetPoint(value.state.setPoint);
    setPower(value.state.power);
  }, [value.state]);

  useEffect(async () => {
    try {
      const response = await API.get("SmartlyAPI", `/devices/${value.deviceId}/admin/routine`, { response: true });
      setRoutines(response.data.parameters);
    } catch (e) {
      console.log(e);
    }
  }, [value.deviceId]);

  useEffect(() => {
    getUUIDToApi();
    getData();
    getAccount().then((value) => setAccount(value));
  }, []);

  return (
    <Col key={index} className={styles.devicesCard} sm={5} xs={12}>
      <Card className={styles.card}>
        <Card.Header className={styles.devicesCardHeader}>
          <Card.Title className={styles.devicesCardTitle}>{value.id}</Card.Title>
          <CloseButton
            className={styles.devicesCardClose}
            onClick={() => removeDevice(value)}
            variant="white"
          />
        </Card.Header>
        <Card.Body className={styles.devicesCardBody}>
          <Col className={styles.devicesCardBodyColumnAround}>
            <Col xs={4} className={styles.routineButton}>
              <Button
                className={styles.routineButton}
                onClick={() => setRoutineModalOpen(true)}
              >Programação</Button>
            </Col>
            <Col xs={4}>
              <div className={styles.textInfos}>
                <Card.Text className={styles.textInfosTitle}>
                  {`Status`}
                </Card.Text>
                {getWiFiConnection(value.state.status, value.state.wifi)}
              </div>
            </Col>
            <Col xs={4}
              className={styles.qrCodeButton}
            >
              <Button
                className={styles.qrCodeButton}
                onClick={() => setQrModalOpen(true)}
              >
                {loadingQRCode ?
                  <Spinner
                    style={{ height: "20px", width: "20px" }} animation='border' variant='light' /> : 'Gerar QR Code'
                }
              </Button>
            </Col>
          </Col>
          <Modal show={qrModalOpen}>
            <Modal.Header closeButton onClick={() => setQrModalOpen(false)}>
              <Modal.Title>QR Code</Modal.Title>
            </Modal.Header>
            <Modal.Body className="d-flex flex-column align-items-center">
              <QRCode id="QRCode" value={uuid} />
            </Modal.Body>
            <Modal.Footer>
              <Button className={button.colorWhite}
                onClick={copyToClipboard} disabled={copied} >
                {copied ? "Copiado" : <>Copiar UUID <FaRegCopy /></>}
              </Button>
              <Button className={button.colorWhite}
                onClick={handleQRcodeDownload}>Baixar QR Code <GetApp /></Button>
            </Modal.Footer>
          </Modal>
          <Modal
            show={routineModalOpen} className={styles.routineModal} size='lg'
          >
            <Modal.Header>
              <Col>
                <Modal.Title className={styles.modalHeader}>
                  <div className={styles.modalTitle}>
                    {`\xa0\xa0\xa0 Programação`}
                  </div>
                  <div
                    className={styles.closeModal}
                    xs={1}
                    onClick={() => setRoutineModalOpen(false)}
                  >
                    <span className="material-symbols-outlined" >close</span>
                  </div>
                </Modal.Title>
              </Col>
            </Modal.Header>
            <Modal.Body className={styles.Body}>
              <div className={styles.Switch}>
                <Form.Switch
                  label={!prog ? 'Rotina' : 'Manual'}
                  checked={Boolean(!prog)}
                  onChange={() => setProg(!prog)}
                />
                <Button
                  className={button.colorWhite}
                  onClick={() => Device && updateConfig({ device: Device, userId: devicesUser[0].userId, smartlyId: value.id })}
                >
                  Alterar Modo
                </Button>
              </div>
              {routines ? (
                Object.keys(routines).length > 0 &&
                daysOfWeek.map((day, index) => {
                  return (
                    <div className={styles.Day} key={day}>
                      <h3 className={styles.day_title}>{returnDayInPortuguese(day)}</h3>
                      <Row className={styles.day_content}>
                        <div>{buildRoutine(day, index)}</div>
                      </Row>
                    </div>
                  )
                })
              ) : (
                <div className={styles.alertMessage}>
                  Sem rotina definida
                </div>
              )
              }
            </Modal.Body>
          </Modal>
          <Col className={styles.devicesCardBodyColumnAround}>
            <div className={styles.textInfos}>
              <Card.Text className={styles.textInfosTitle}>
                {`${value.state.operation ? "Temp. Piso" : "Temp. Ambiente"}`}
              </Card.Text>
              {`${value.state.temp}°C`}
            </div>
            <div className={styles.textInfos}>
              <Card.Text className={styles.textInfosTitle}>
                {`Set Point`}
              </Card.Text>
              {`${value.state.setPoint}°C`}
            </div>
            <div className={styles.textInfos}>
              <Card.Text className={styles.textInfosTitle}>
                {`Umidade`}
              </Card.Text>
              {`${value.state.hum}%`}
            </div>
          </Col>
          <Col className={styles.devicesCardBodyColumnAround}>
            <div className={styles.textInfos}>
              <Card.Text className={styles.textInfosTitle}>
                {`Sensor Piso`}
              </Card.Text>
              {`${value.state.floor_sensor ? "Sim" : "Não"}`}
            </div>
            <div className={styles.textInfos}>
              <Card.Text className={styles.textInfosTitle}>
                {`Piso`}
              </Card.Text>
              {`${value.state.floor_temp}°C`}
            </div>
            <div className={styles.textInfos}>
              <Card.Text className={styles.textInfosTitle}>
                {`Calefação`}
              </Card.Text>
              {`${value.state.heating ? "Ligado" : "Desligado"}`}
            </div>
          </Col>
          <Col className={styles.devicesCardBodyColumnAround}>
            <div className={styles.textInfos}>
              <Card.Text className={styles.textInfosTitle}>
                {`Usuário`}
              </Card.Text>
              {user.length > 1 ? user : 'Nenhum usuário cadastrado'}
            </div>
            <div className={styles.textInfos}>
              <Card.Text className={styles.textInfosTitle}>
                {`Data de Registro`}
              </Card.Text>
              {regDate}
            </div>
          </Col>
          <Col className={styles.devicesCardBodyColumnRow}>
            <Slider
              className={styles.slider}
              onChange={(event) => updateSetPoint(event)}
              step={0.5}
              min={5}
              max={35}
              value={setPoint}
            />
            <Card.Text
              className={styles.devicesCardBodySetpoint}
            >{`${setPoint.toFixed(1)}°C`}</Card.Text>
          </Col>
          <Col className={styles.devicesCardBodyColumnRow}>
            <Form.Switch
              className={styles.text}
              bsPrefix="devices-card-switch"
              label="Power"
              checked={Boolean(power)}
              onChange={(event) => setPower(!power)}
            />
          </Col>
          {
            showGaug ?
              <>
                <Col className={styles.devicesCardBodyColumnRow}>
                  <Card.Text className={styles.textInfosTitle}>
                    {`Aferir sensor`}
                  </Card.Text>
                </Col>
                <Col className={styles.devicesCardBodyColumnRow}>
                  <Slider
                    className={styles.slider}
                    onChange={(event) => setGauging(event)}
                    step={0.5}
                    min={-5}
                    max={5}
                    value={gauging}
                  />
                  <Card.Text
                    className={styles.devicesCardBodySetpoint}
                  >{`${Math.sign(gauging.toFixed(1)) === 1 ? "+" : ""}${gauging.toFixed(1)}°C`}</Card.Text>
                </Col>
                <Col className={styles.devicesCardBodyColumnRow}>
                  <ExpandLessSharp
                    className={styles.devicesCardExpand}
                    htmlColor={Colors.gray}
                    size={20}
                    onClick={handleShowGaug}
                  />
                </Col>
              </>
              :
              <Col className={styles.devicesCardBodyColumnRow}>
                <ExpandMoreSharp
                  className={styles.devicesCardExpand}
                  htmlColor={Colors.gray}
                  size={20}
                  onClick={handleShowGaug}
                />
              </Col>
          }
          <Button
            className={button.colorWhite}
            onClick={sendState}
          >Enviar</Button>
        </Card.Body>
      </Card>
    </Col>
  );
}