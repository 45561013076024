import { Col, Row } from "react-bootstrap";
import styles from './Local.module.scss';

/**
 * build the component locals
 * @param {*} param0 
 * @returns {React.Component} `component`
 */
function Local({locals}) {
  const name = locals[0].Name;
  const street = locals[0].Street
  const number = locals[0].Number
  const complement = locals[0].Complement
  const city = locals[0].City
  const state = locals[0].State
  const cep = locals[0].Cep

  return ( 
    <div className={styles.locals}>
      <Row sm={12} xs={4}>
        <Col md={4} xs={12} className={styles.textName}>
          {`Nome: ${name}`}
        </Col>
        <Col md={4} xs={12} className={styles.textStreet}>
          {`Rua: ${street} nº: ${number}`}
        </Col>
        <Col md={4} xs={12} className={styles.textComplement}>
          {`Complemento: ${complement}`}
        </Col>
      </Row>
      <Row sm={12} xs={4}>
        <Col md={6} xs={12} className={styles.textCity}>
          {`Cidade: ${city} / ${state}`}
        </Col>
        <Col md={6} xs={12} className={styles.textCep}>
          {`CEP: ${cep}`}
        </Col>
      </Row>
    </div> 
  );
}

export default Local;