import React, { useContext } from "react";
import { Container, Row } from "react-bootstrap";
import Devices from "../../context/devices";
import { Thermostat, HotCloset } from "../DeviceCard";
import styles from './DeviceList.module.scss';

/**
 * build list of devices
 * @returns {React.Component} `component`
 */

export default function DeviceList(props){
  const context = useContext(Devices);
  const {device} = context;

  /**
   * @param {String} id 
   * @param {Number} index 
   * @param {Device} value 
   * @returns get device type and build card device
   */
  function getDeviceType(id, index, value){
    if(id.indexOf("SmartLy-th-") !== -1)
      return <Thermostat key={index} value={value} index={index}/>;
    else if(id.indexOf("SmartLy-hc-") !== -1)
      return <HotCloset key={index} value={value} index={index}/>;;
  }

  return (
    <Container className={styles.devicesList} >
      <Row className={styles.devicesList}>
        {
          device.length ? 
            device.map((value, index) => getDeviceType(value.id, index, value))
          :
            <></>
        }
      </Row>
    </Container>
  );
}