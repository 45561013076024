import Edit from '../../../../assets/Icons/editar.js'
import styles from './SupportTable.module.scss'
import { createSearchParams, useNavigate } from 'react-router-dom';

const SupportTable = ({data}) => {
  const navigate = useNavigate();

  function dateConverter(timestamp) {
    let day = new Date(timestamp).getDate();
    let month = new Date(timestamp).getMonth()+1;
    let year = new Date(timestamp).getFullYear();
    const date = `${day.toString().length < 2 ? "0" + day : day}/${month.toString().length < 2 ? "0" + month : month}/${String(year).slice(2, 4)}`
    return date;
  }

  async function handleEdit(timestamp) {
    navigate({
      pathname: "edit",
      search: createSearchParams(
        {
          id: timestamp,
        }
      ).toString()
    })
  }
  

  return (
    <table className={styles.table}>
      <thead>
        <tr>
          <th className={styles.thead}>Data</th>
          <th className={styles.thead}>Solicitante</th>
          <th className={styles.thead}>Usuário APP</th>
          <th className={styles.thead}>Produto</th>
          <th className={styles.thead}>Questionamento</th>
          <th className={styles.thead}>Solução</th>
          <th className={styles.thead}><Edit/></th>
        </tr>
      </thead>
      <tbody>
      {data.map((value) => (
        <tr key={value.timestamp} className={styles.trow}>
          <td className={styles.tdata}>
            {dateConverter(parseInt(value.timestamp)*1000)
          }</td>
          <td className={styles.tdata}>{value.requester}</td>
          <td className={styles.tdata}>{value.username}</td>
          <td className={styles.tdata}>{value.product}</td>
          <td className={`${styles.tdata} ${styles.customWidth}`}>
            <div style={{maxHeight:"50px", overflow:"auto"}}>
              {value.question}
            </div>
          </td>
          <td className={`${styles.tdata} ${styles.customWidth}`}>
            <div style={{maxHeight:"50px", overflow:"auto"}}>
              {value.solution}
            </div>
          </td>
          <td className={`${styles.tdata} ${styles.editWidth}`}>
            <div className={styles.edit}
            onClick={() => handleEdit(value.timestamp)}>
              Editar
            </div>
          </td>
        </tr>
        ))}
      </tbody>
    </table>
  )
}

export default SupportTable;