const Colors = {
  primary: "#e3e3e2",
  secondary: "#5bb1c9",
  secondaryLight: "#9dd0df",
  white: "#FFFFFF",
  text: "#505051",
  gray: "#c4c4c4",
  redLight: "#f2a0a1",
  greenLight: "#cffdbc"
}

export default Colors;