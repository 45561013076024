import React, { useState, useEffect, useContext } from "react";
import { Button, Card, Col, Form, Row } from "react-bootstrap";
import {
  KeyboardArrowDownOutlined,
  KeyboardArrowUpOutlined,
} from "@material-ui/icons";
import Slider from 'rc-slider';
import { createSearchParams, useNavigate } from 'react-router-dom';

import { isDeviceAdd } from "utils/Device";
import { isConnected, statusWifi, verifyStatus } from "utils/Cards";
import { getModel } from "service/Device";

import { AlertContext } from 'context/alert';
import { SupportContext } from "context/support";

import styles from './MiniHotCloset.module.scss'
import button from 'assets/Button/button.module.scss';

/**
 * build the small hotcloset card configuration
 * @returns {React.Component} `component`
 */
function MiniHotCloset({ device: deviceInfos, user }) {
  const { setMessageAlert, setErrorAlert, setShowAlert, setSuccessAlert } = useContext(AlertContext);
  const { addDevicesToLocalStorage } = useContext(SupportContext);
  const [deviceId, setDeviceId] = useState('-');
  const [deviceWifi, setDeviceWifi] = useState('-');
  const [deviceSetPoint, setDeviceSetPoint] = useState('-');
  const [modifySetPoint, setModifySetPoint] = useState(0);
  const [isSubscribed, setIsSubscribed] = useState(false);
  const [isDropDownOpen, setIsDropDownOpen] = useState(false);
  const [power, setPower] = useState(false);
  const [model, setModel] = useState('')

  const navigate = useNavigate();

  useEffect(() => {
    async function getDeviceModel(){
      if(deviceInfos){
        setModel(await getModel(deviceInfos.id));
      }
    }

    setDeviceId(deviceInfos.id)

    setDeviceWifi(deviceInfos.device.status === 'connected' ? deviceInfos.device.wifi : -1);
    setDeviceSetPoint(deviceInfos.device.hum_setpoint);
    setModifySetPoint(deviceInfos.device.hum_setpoint);
    setPower(deviceInfos.device.power)
    setIsSubscribed(true);
    
    getDeviceModel()

    deviceInfos.callback((status) => {
      setDeviceWifi(status.status === 'connected' ? status.wifi : -1);
      setDeviceSetPoint(status.hum_setpoint);
      setModifySetPoint(deviceInfos.device.hum_setpoint);
      setPower(deviceInfos.device.power)
      setIsSubscribed(true);
    })
  }, [deviceInfos])

  function handleDropDown(){
    setIsDropDownOpen(!isDropDownOpen);
  }

  /**
   * manager update config and show message success or error
   */
  async function handleUpdateConfig(){
    window.scrollTo(0,0);
    
    if( 
      power === deviceInfos.device.power &&
      modifySetPoint === deviceInfos.device.setPoint             
    ){
      setMessageAlert('Nenhuma informação alterada');
      setErrorAlert(true);
      setShowAlert(true);
      return      
    }   

    if(!isDeviceAdd(deviceInfos))
    addDevicesToLocalStorage(deviceInfos.id)

    const oldInfos = {
      power: power,
      setPoint: deviceSetPoint
    }
    
    deviceInfos.updateConfig({hum_setpoint: modifySetPoint, power: power});

    var count = 0;
    var idInterval = setInterval(async ()=>{ 

      if( 
          oldInfos.power !== deviceInfos.device.power ||
          oldInfos.setPoint !== deviceInfos.device.setPoint             
        ){
          setMessageAlert('Dispositivo atualizado com sucesso');
          setSuccessAlert(true);
          setShowAlert(true)
          clearTimeout(idInterval);
          
        }        
        if(count>5){
          setMessageAlert('Erro ao atualizar o dispositivo');
          setErrorAlert(true);
          setShowAlert(true);
          clearTimeout(idInterval);
        }
      count++
    }, 1000);
  }

  return ( 
  <>
    <Col sm={4} xs={12}>
      <Card className={styles.card}>
        <Card.Header 
          className={styles.cardHeader}
          style={verifyStatus(deviceInfos, isSubscribed)}
          >
          <Card.Title
            className={styles.title}
            onClick={() => 
              navigate({
                pathname: "device/",
                search: createSearchParams(
                  {
                    username: user.username,
                    email: user.email,
                    name: user.name,
                    userId: user.userId,
                    smartlyId: deviceInfos.id,
                    deviceId: deviceInfos.deviceId,
                    localName: deviceInfos.localName,
                    localId: deviceInfos.local_id,
                    deviceName: deviceInfos.name
                  }
                ).toString()
              })
            }  
          >
            <div className={styles.fontTitle}>
              <span className={styles.deviceName}>{deviceInfos.name}</span> - ( 
              <span> {deviceId}</span> )
              <span> - {String(model)}</span>
            </div>
          </Card.Title>
        </Card.Header>
        <Card.Body className={styles.cardBody}>
          <div>
            <Row>
              <div className={isConnected(isSubscribed, deviceInfos) ? styles.cardBodyText : styles.cardBodyTextDisconnect}>
                {statusWifi(isSubscribed, deviceWifi)}
                <span className={styles.humidityText}>
                  {deviceInfos.device.hum}% / {deviceSetPoint}%  
                  <span className="material-symbols-outlined">
                    water_drop
                  </span>
                </span>
                <span></span>
                <span 
                  className="material-symbols-outlined"
                  style={
                    deviceInfos.device.power === 1 ? {} : {color: 'red'} 
                  }
                  >
                  power_settings_new
                </span>
                <div className={styles.arrowDown} onClick={() => handleDropDown()}>
                  {isDropDownOpen? 
                  <KeyboardArrowUpOutlined /> :
                  <KeyboardArrowDownOutlined /> 
                }
                </div>
              </div>
            </Row>
            {isDropDownOpen &&
            <>
              <Row>
                <Form.Switch 
                  className={styles.formSwitch}
                  label="Power"
                  checked={Boolean(power)}
                  onChange={() => setPower(!power)}
                />
              </Row>
              <Row className={styles.rowSlider}>
                <Row className={styles.setPoint}>
                  Set-Point
                </Row>
                <div className={styles.dropDownOpen}>
                  <Slider 
                    className={styles.slider}
                    onChange={(e) => setModifySetPoint(e)}
                    step={5}
                    min={10}
                    max={100}
                    value={modifySetPoint}
                  />
                  <Card.Text>
                    {modifySetPoint}%
                  </Card.Text>
                </div>
              </Row>
              <Row>
                <div className={styles.dropDownOpen}>
                  <Button 
                    className={button.colorWhite}
                    onClick={() => handleUpdateConfig()}
                  >Enviar</Button>
                </div>
              </Row>
            </>
            }
          </div>
        </Card.Body>
      </Card>
    </Col>
  </> );
}

export default MiniHotCloset;