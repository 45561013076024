import { TableBody, TableCell, TableHead, TableRow } from "@material-ui/core";
import { Table, Row  } from "react-bootstrap";

import styles from './TableSupport.module.scss';

/**
 * build the table support
 * @returns {React.Component} `component`
 */
function TableSupport({ support }) {
  const headerTable = [ "Descrição", "Solução" ];

  return ( 
    <Table className={styles.table}>
      <TableHead className={styles.tableHead}>
        {headerTable.map((header) => <TableCell className={styles.tableHeadCell}>{header}</TableCell>)}
      </TableHead>
      <TableBody>
        {support.map((error) => <TableRow className={styles.tableBodyRow}>
          <TableCell>{error.describe}</TableCell>
          <TableCell>{error.solution.map((solution, index) => <Row>{index+1}° - {solution}</Row>)}</TableCell>
        </TableRow>)}
      </TableBody>
    </Table>
   );
}

export default TableSupport;