import React from "react";
import { Col } from "react-bootstrap";
import style from "./style.module.scss";

/**
 * build the component for routine
 * @returns {React.Component} `component`
 */
function Routine({time, temp}){
  return (
    <Col sm={3} xs={4} className={style.Card} >
      <div className={style.card_content}>
        <div className={style.time}>
          {time}
        </div>
        <div className={style.temp}>
          {temp}
        </div>
      </div>
    </Col>
  );
}

export default Routine;