import React, { useState } from "react";

export const AlertContext = React.createContext()
AlertContext.displayName = 'Alerts';

export const AlertProvider = ({ children }) => {
  const [errorAlert, setErrorAlert] = useState(false);
  const [successAlert, setSuccessAlert] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const [messageAlert, setMessageAlert] = useState('');

  return (
    <AlertContext.Provider
      value={{
        errorAlert, 
        successAlert, 
        setErrorAlert, 
        setSuccessAlert,
        showAlert, 
        setShowAlert,
        messageAlert,
        setMessageAlert
      }}
    >
      {children}
    </AlertContext.Provider>
  )

}