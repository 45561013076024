import React, { useState, useEffect } from "react";
import ReactDOM from 'react-dom';
import Colors from "../../../assets/Colors";
import Logo from "../../../assets/Images/smartly-logo.png";
import { Col, Container, Form, Button, Image, Alert, Spinner, Row } from "react-bootstrap";
import { Auth } from "aws-amplify";
import { Link, useNavigate } from "react-router-dom";
import { attachPolicy } from "../../../service/Api";

import Input from "@material-ui/core/Input";
import InputAdornment from "@material-ui/core/InputAdornment";
import IconButton from "@material-ui/core/IconButton";
import OutlinedInput from "@material-ui/core/OutlinedInput";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import Visibility from "@material-ui/icons/Visibility";
import styles from './SignIn.module.scss';

export default function SignIn(){

  const navigate = useNavigate();
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [name, setName] = useState("");
  const [confirmPass, setConfirmPass] = useState("");
  const [requiresNewPass, setRequiresNewPass] = useState(false);
  const [ref, setRef] = useState("");
  const [loading, setLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [error, setError] = useState(false);
  const [errorNewPass, setErrorNewPass] = useState(
    {
      confirm: false,
      usernameLength: false,
      upercase: false,
      lowcase: false,
      number: false,
      special: false,
      is: false
    }
  );

  useEffect(async () => {
    try{
      if(await Auth.currentAuthenticatedUser() && sessionStorage.getItem('jwtToken'))
      navigate("/main", {replace: true});
    }catch{
    }
  }, []);

  function checkPassChar(){
    let errors = {
      confirm: false, 
      length: false,
      upercase: false,
      lowcase: false,
      number: false,
      special: false,
      is: false
    };

    const passwordTestArray = password.split('');

    const result = passwordTestArray.map((char) => {
      if (char === char.toUpperCase() && isNaN(char)) {
        return 'upercase';
      }

      if (char === char.toLowerCase() && isNaN(char)) {
        return 'lowcase';
      }

      if (!isNaN(char)) {
        return 'number';
      }
    });

    function containsSpecialChars(str) {
      const specialChars = /[`!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/;
      return specialChars.test(str);
    }

    if(containsSpecialChars(password)){
      errors.is = true;
      errors.special = true;
    }
    
    if (result.indexOf('upercase') >= 0) {
    } else {
      errors.is = true;
      errors.upercase = true;
    }
    if (result.indexOf('lowcase') >= 0) {
    } else {
      errors.is = true;
      errors.lowcase = true;
    }

    if (result.indexOf('number') >= 0) {
    } else {
      errors.is = true;
      errors.number = true;
    }

    if(password !== confirmPass){
      errors.is = true;
      errors.confirm = true;
    }

    setErrorNewPass(errors);

    if(errors.is){
      return false;
    }else{
      return true;
    }
  }

  function setInputFocus(r) {
    ReactDOM.findDOMNode(r).focus();
  }

  async function handleSubmit(event){
    event.preventDefault();
    setError(false);
    setLoading(true);
    if(!requiresNewPass){
      if(username.length > 0 && password.length >= 8){
        const responseAuth = await Auth.signIn({username, password})
          .catch(() => {
            setLoading(false);
            setError(true);
          });
        const userSession = await Auth.currentSession();
        sessionStorage.setItem('jwtToken', userSession.idToken.jwtToken)
        if(responseAuth){
          if(responseAuth.challengeName === "NEW_PASSWORD_REQUIRED"){
            setLoading(false);
            setInputFocus(ref);
            setPassword("");
            setRequiresNewPass(responseAuth);
            return;
          }
          await attachPolicy();
          setLoading(false);
          return navigate("/main", {replace: true});
        }
      }else{
        setLoading(false);
        setError(true);
        return false;
      }
    }else{
      if(checkPassChar()){
        const responseNewPass = await Auth.completeNewPassword(
          requiresNewPass, 
          password, 
          {
            "name": name
          }
        );

        if(responseNewPass){
          setLoading(false);
          return navigate("/main", {replace: true});
        }
        setLoading(false);
      }
      setLoading(false);
    }
    setLoading(false);
  }

  return (
    <Form onSubmit={handleSubmit}>
      <Container className={styles.signinContent}>
        <Col sm={8} md={3} xs={10} className={styles.signinColumn}>
          <Image 
            src={Logo}
            fluid
          />
          {
            !requiresNewPass ?
              <>
                {
                  error ? 
                    <Alert variant="danger">
                      Usuário ou Senha está incorreto!
                    </Alert>
                  :
                    <></>
                }
                <OutlinedInput 
                  className={styles.signinInput}
                  type="text"
                  name="username"
                  placeholder="Usuário"
                  value={username}
                  onChange={(value) => setUsername(value.target.value)}
                  style={{width: '100%'}}
                />
                <OutlinedInput
                  id="inputPassword"
                  className={styles.signinInput}
                  type={showPassword? "text" : "password" }
                  placeholder="Senha"
                  value={password}
                  variant="outlined"
                  onChange={(value) => setPassword(value.target.value)}
                  style={{width: '100%'}}
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        onClick={() => setShowPassword(!showPassword)}
                      >
                        {showPassword ? <Visibility /> : <VisibilityOff />}
                      </IconButton>
                    </InputAdornment>
                  }
                />
                <Button
                  className={styles.signinSubmit}
                  variant="primary"
                  style={{
                    color: Colors.white
                  }}
                  type="submit"
                >
                  {
                    loading ?
                      <Spinner 
                        style={{height: "20px", width: "20px"}}
                        animation="border" 
                        variant="light" 
                      />
                    :
                      "Entrar"
                  }
                </Button>
                <div xs={12} className={styles.forgotPasswordRow}>
                  <div>
                    Esqueceu a senha?
                  </div>
                  <Link to='/forgotPassword'>
                    clique aqui
                  </Link>
                </div>
              </> :
              <>
                {
                  errorNewPass.is ? 
                    <Alert variant="danger">
                      {
                        errorNewPass.length ? <>Senha contém menos que oito caracteres!<br/></>: ""
                      }
                      {
                        errorNewPass.lowcase ? <>Senha deve conter uma letra minuscula!<br/></> : ""
                      }
                      {
                        errorNewPass.upercase ? <>Senha deve conter uma letra maiuscula!<br/></> : ""
                      }
                      {
                        errorNewPass.number ? <>Senha deve conter um número!<br/></> : ""
                      }
                      {
                        errorNewPass.special ? <>A senha apenas deve conter letras e números!<br/></> : ""
                      }
                      {
                        errorNewPass.confirm ? <>Senhas são diferentes!<br/></>: ""
                      }
                    </Alert>
                  :
                    <></>
                }
                <Form.Control
                  className={styles.signinInput}
                  type="text"
                  placeholder="Nome completo"
                  value={name}
                  onChange={(value) => setName(value.target.value)}
                />
                <Form.Control
                  className={styles.signinInput}
                  type="password"
                  placeholder="Nova senha"
                  value={password}
                  onChange={(value) => setPassword(value.target.value)}
                />
                <Form.Control
                  className={styles.signinInput}
                  type="password"
                  placeholder="Confirmar senha"
                  value={confirmPass}
                  onChange={(value) => setConfirmPass(value.target.value)}
                />
                <Button
                  className={styles.signinSubmit}
                  variant="primary"
                  style={{
                    color: Colors.white
                  }}
                  type="submit"
                >
                  {
                    loading ?
                      <Spinner 
                        style={{height: "20px", width: "20px"}}
                        animation="border" 
                        variant="light" 
                      />
                    :
                      "Confirmar"
                  }
                </Button>
              </>
          }
          
        </Col>
      </Container>
    </Form>
  );
}