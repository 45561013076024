import SupportScheduleImport from "./SupportSchedule";
import SupportTechnicianImport from "./SupportTechnician";
import SupportClientImport from "./SupportClient";
import InsertCallImport from "./SupportClient/InsertCall";
import EditCallImport from "./SupportClient/EditCall";

export const SupportSchedule = SupportScheduleImport;
export const SupportTechnician = SupportTechnicianImport;
export const SupportClient = SupportClientImport;
export const InsertCall = InsertCallImport;
export const EditCall = EditCallImport;