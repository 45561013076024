/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-east-1",
    "aws_cognito_identity_pool_id": "us-east-1:bd74c686-9f2c-45d6-8563-d035e89755ed",
    "aws_cognito_region": "us-east-1",
    "aws_user_pools_id": "us-east-1_ApKL4DUr1",
    "aws_user_pools_web_client_id": "3j5lgbcooi3rvqtn7c1vbps7qf",
    "oauth": {
        "domain": "smartlyadmin.auth.us-east-1.amazoncognito.com"
    },
    "aws_cognito_username_attributes": [],
    "aws_cognito_social_providers": [],
    "aws_cognito_signup_attributes": [
        "NAME",
        "EMAIL",
        "PHONE_NUMBER"
    ],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_cognito_mfa_types": [],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": [
            "REQUIRES_LOWERCASE",
            "REQUIRES_UPPERCASE",
            "REQUIRES_NUMBERS"
        ]
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ],
    "accessKeyId": "AKIAYSPWWM6RI33IHWV5",
    "secretAccessKey": "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"
};


export default awsmobile;
