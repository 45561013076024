import { useState } from "react";
import { Alert, Button, Col, Form, Row } from "react-bootstrap";
import styles from './FormChangePass.module.scss';
import button from 'assets/Button/button.module.scss';
import { useEffect } from "react";

import InputAdornment from "@material-ui/core/InputAdornment";
import IconButton from "@material-ui/core/IconButton";
import OutlinedInput from "@material-ui/core/OutlinedInput";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import Visibility from "@material-ui/icons/Visibility";
import { Auth } from "aws-amplify";
import { useNavigate } from "react-router-dom";

export default function FormRequestCode({ username, setCodeRequested }){
  const [code, setCode] = useState()
  const [newPassword, setNewPassword] = useState('');
  const [confirmPass, setConfirmPass] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPass, setShowConfirmPass] = useState(false);
  const [passEmpty, setPassEmpty] = useState(true);
  const [errorNewPass, setErrorNewPass] = useState(
    {
      confirm: false,
      usernameLength: false,
      upercase: false,
      lowcase: false,
      number: false,
      special: false,
      length: false,
      is: false,
      moreThan10: false
    }
  );

  const navigate = useNavigate();

  async function handleSubmit(){
    checkPassChar();
    
    if(!errorNewPass.is){
      setCodeRequested(false);
      navigate('/login');
      return
    }
    if(errorNewPass.is)
      return

    try {
      await Auth.forgotPasswordSubmit(username, code, newPassword)
    } catch (error) {
      console.log(error) 
    }
  }

  function handleNewPass(newPass){
    setNewPassword(newPass);
  }

  function handleConfirmPass(confirm){
    setConfirmPass(confirm);
  }
  
  useEffect(() => {
    if(confirmPass.length < 1 && newPassword.length < 1){
      setPassEmpty(true);
    }
    else{
      setPassEmpty(false);
    }
    checkPassChar();

  }, [confirmPass, newPassword])

  function checkPassChar(){
    let errors = {
      confirm: false, 
      length: false,
      upercase: false,
      lowcase: false,
      number: false,
      special: false,
      is: false,
      first: false,
      moreThan10: false
    };

    const passwordTestArray = newPassword.split('');

    const result = passwordTestArray.map((char) => {
      if (char === char.toUpperCase() && isNaN(char)) {
        return 'upercase';
      }

      if (char === char.toLowerCase() && isNaN(char)) {
        return 'lowcase';
      }

      if (!isNaN(char)) {
        return 'number';
      }
    });

    function containsSpecialChars(str) {
      const specialChars = /[`!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/;
      return specialChars.test(str);
    }

    if(containsSpecialChars(newPassword)){
      errors.is = true;
      errors.special = true;
    }
    
    if (result.indexOf('upercase') >= 0) {
    } else {
      errors.is = true;
      errors.upercase = true;
    }
    if (result.indexOf('lowcase') >= 0) {
    } else {
      errors.is = true;
      errors.lowcase = true;
    }

    if (result.indexOf('number') >= 0) {
    } else {
      errors.is = true;
      errors.number = true;
    }

    if(newPassword !== confirmPass){
      errors.is = true;
      errors.confirm = true;
    }

    if(newPassword.length < 8){
      errors.is = true;
      errors['length'] = true;
    }

    if(newPassword.length > 10){
      errors.is = true;
      errors.moreThan10 = true;
    }

    setErrorNewPass(errors);

    if(errors.is){
      return false;
    }else{
      return true;
    }
  }

  return(
    <>
      <Col xs={12}>
        <OutlinedInput 
          className={styles.inputStandard}
          type="text"
          placeholder="Código"
          value={code}
          onChange={(value) => setCode(value.target.value)}
          style={{width: '100%'}}
        />
        <OutlinedInput
          className={styles.inputStandard}
          type={showPassword? "text" : "password" }
          placeholder="Nova senha"
          value={newPassword}
          onChange={(e) => handleNewPass(e.target.value)}
          style={{width: '100%'}}
          endAdornment={
            <InputAdornment position="end">
              <IconButton
                onClick={() => setShowPassword(!showPassword)}
              >
                {showPassword ? <Visibility /> : <VisibilityOff />}
              </IconButton>
            </InputAdornment>
          }
        />
        <OutlinedInput
          className={styles.inputStandard}
          type={showConfirmPass? "text" : "password" }
          placeholder="Confirmar nova senha"
          value={confirmPass}
          onChange={(e) => handleConfirmPass(e.target.value)}
          style={{width: '100%'}}
          endAdornment={
            <InputAdornment position="end">
              <IconButton
                onClick={() => setShowConfirmPass(!showConfirmPass)}
              >
                {showConfirmPass ? <Visibility /> : <VisibilityOff />}
              </IconButton>
            </InputAdornment>
          }
        />
        <Row >
          <Col xs={12}>
            <Button 
              onClick={() => handleSubmit()}
              className={[button.colorWhite, button.width100]}
            >
              Salvar senha
            </Button>
          </Col>
        </Row>
      </Col>
      {
        errorNewPass.is && !passEmpty ? 
          <Alert variant="danger">
            {
              errorNewPass.length ? <>Senha contém menos que oito caracteres!<br/></>: ""
            }
            {
              errorNewPass.moreThan10 ? <>Senha não pode conter mais que 10 caracteres!<br/></>: ""
            }
            {
              errorNewPass.lowcase ? <>Senha deve conter uma letra minuscula!<br/></> : ""
            }
            {
              errorNewPass.upercase ? <>Senha deve conter uma letra maiuscula!<br/></> : ""
            }
            {
              errorNewPass.number ? <>Senha deve conter um número!<br/></> : ""
            }
            {
              errorNewPass.special ? <>A senha apenas deve conter letras e números!<br/></> : ""
            }
            {
              errorNewPass.confirm ? <>Senhas são diferentes!<br/></>: ""
            }
            
          </Alert>
        :
          <></>
      }
    </>
  )
}