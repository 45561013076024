import { Container, Row, Col, Button, Image } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import Logo from "assets/Images/smartly-logo.png";
import button from 'assets/Button/button.module.scss';
import styles from './NotAuthorized.module.scss';

/**
 * build the page not authorized
 * @returns {React.Component} `component`
 */
function NotAuthorized() {
  const navigate = useNavigate();

  return ( 
    <Container className={styles.body}>
      <Row className={styles.row}>
        <Col sm={1} xs={3}
          className={styles.back}
        >
          <Button
            className={button.colorWhite}
            onClick={() => navigate(-1)}
          >
            Voltar
          </Button>
        </Col>
      </Row>
      <Col sm={4} className={styles.message}>
        <Image src={Logo} fluid/>
        <div className={styles.message}>
          <Row>
            Não Autorizado
          </Row>
          <Row>
            Você não tem permissão para acessar essa página
          </Row>
        </div>
      </Col>
    </Container> 
   );
}

export default NotAuthorized;