import React, { useState } from 'react';

export const SupportContext = React.createContext();
SupportContext.displayName = 'Support'

export const SupportProvider = ({ children }) => {
  const [attendant, setAttendant] = useState({});
  const [log, setLog] = useState([]);
  const [alterInfos, setAlterInfos] = useState({});
  const [assistanceStarted, setAssistanceStarted] = useState(false)

  function addLog(){
    setLog(...log, alterInfos);
  }

  /**
   * recive username and addo to local storage
   * @param {String} newUser 
   */
  function addUsersServedToLocalStorage(newUser){
    var users = localStorage.getItem('usersServed')
    if(users){
      localStorage.setItem('usersServed', `${users} ${String(newUser)}`)
      return
    }

    localStorage.setItem('usersServed', String(newUser))
  }

  /**
   * recive device Id and add to local storage
   * @param {String} device 
   * @returns 
   */
  function addDevicesToLocalStorage(device){
    var devices = localStorage.getItem('devices')
    if(devices){
      const stringTeste = `${devices} ${String(device)}`;
      localStorage.setItem('devices', stringTeste)
      return
    }

    localStorage.setItem('devices', String(device))
  }

  /**
   * get user in local storage
   * @returns {String}
   */
  function getUsersServed(){
    return localStorage.getItem('usersServed')
  }

  /**
   * clear users in local storage
   */
  function clearUser(){
    localStorage.removeItem('usersServed');
  }

  /**
   * clear devices in local storage
   */
  function clearDevices(){
    localStorage.removeItem('devices');
  }

  return (
    <SupportContext.Provider
      value={{
        addUsersServedToLocalStorage,
        getUsersServed,
        attendant,
        setAttendant,
        log,
        addLog,
        alterInfos,
        setAlterInfos,
        addDevicesToLocalStorage,
        clearUser,
        clearDevices,
        assistanceStarted,
        setAssistanceStarted,
      }}
    >
      {children}
    </SupportContext.Provider>
  )
}