import React, {useState} from 'react';
import {Card, Col, Form, Button, Alert, Row} from 'react-bootstrap';
import Icon from '@mdi/react';
import {
  mdiCheckBold,
  mdiWindowClose
} from '@mdi/js';
import { Auth } from 'aws-amplify';
import {
  hasNumber,
  hasLowercase,
  hasUppercase
} from 'utils/Password'
import PhoneInput from 'react-phone-number-input';
import pt from 'react-phone-number-input/locale/pt-BR.json';

import styles from './CardRegister.module.scss';
import button from 'assets/Button/button.module.scss';

/**
 * build card register user in system
 * @returns {React.Component} `component`
 */
function CardRegister() {
  const [username, setUserName] = useState('');
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [role, setRole] = useState(-1);
  const [error, setError] = useState(false);
  const [fullPhone, setFullPhone] = useState('');
  const [errorNewPass, setErrorNewPass] = useState(
    {
      confirm: false,
      usernameLength: false,
      upercase: false,
      lowcase: false,
      number: false,
      special: false,
      is: false,
      passwordLength: false,
      nameLength: false,
      phoneEmpty: false,
      invalidEmail: false
    }
  )

  const roles = [
    {
      name: 'admin',
      value: 'admin'
    },{
      name: 'instalador',
      value: 'installer'
    },{
      name: 'suporte',
      value: 'suport'
    }];

  /**
   * check errors
   * @returns {String} errors in inputs
   */
  function returnErrors(){    
    if(errorNewPass.nameLength) 
      return 'Nome deve conter mais que 3 caracteres'
    
    if(errorNewPass.usernameLength) 
      return 'Usuário deve conter mais que 3 caracteres'

    if(errorNewPass.invalidEmail)
      return 'E-mail inválido'
    
    if(errorNewPass.special) 
      return 'Senha não deve conter caracteres especiais'
    
    if(errorNewPass.passwordLength) 
      return 'Senha deve conter mais que 8 caracteres'
    
    if(errorNewPass.upercase) 
      return 'Senha deve conter pelo menos uma maiúscula'
    
    if(errorNewPass.lowercase) 
      return 'Senha deve conter pelo menos uma minúscula'
    
    if(errorNewPass.confirm) 
      return 'Senhas não conferem'
    
    if(errorNewPass.number) 
      return 'Senha deve conter pelo menos um número'

    if(errorNewPass.role) 
      return 'Selecione um cargo'

    if(errorNewPass.phoneEmpty){
      return 'Telefone não pode estar vazio'
    }
  }

  /**
   * verify inputs and set errors if exist
   */
  function checkInputs(){
    let errors = {
      confirm: false,
      usernameLength: false,
      upercase: false,
      lowcase: false,
      number: false,
      special: false,
      is: false,
      passwordLength: false,
      nameLength: false,
      phoneEmpty: false,
      invalidEmail: false
    };
    
    function containsSpecialChars(str){
      const specialChars = /[`!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/;
      return specialChars.test(str);
    }

    if(!hasUppercase(password)){
      errors.is=true;
      errors.upercase = true
    }

    if(!hasLowercase(password)){
      errors.is = true;
      errors.lowercase = true;
    }

    if(!hasNumber(password)){
      errors.is = true;
      errors.number = true;
    }

    if(containsSpecialChars(password)){
      errors.is = true;
      errors.special = true;
    }

    if(name.length<3){
      errors.is = true;
      errors.nameLength = true;
    }

    if(password !== confirmPassword){
      errors.is = true;
      errors.confirm = true;
    }

    if(password.length <8){
      errors.is = true;
      errors.passwordLength = true
    }

    if(username.length < 3){
      errors.is = true;
      errors.usernameLength = true;
    }
    
    if(role == -1){
      errors.is = true;
      errors.role = true;
    }

    if(!email.includes('@') || !email.includes('.') || email.length<5){
      errors.is = true;
      errors.invalidEmail= true;
    }

    if(fullPhone.length <= 0){
      errors.is = true;
      errors.phoneEmpty = true;
    }

    setErrorNewPass(errors)

    if(errors.is){
      setError(true)
      return false
    } else{
      setError(false)
      return true
    }
  }

  function clearInputs(){
    setPassword('');
    setName('');
    setUserName('');
    setConfirmPassword('');
    setEmail('');
    setRole(-1);
    setFullPhone('');
  }

  /**
   * manager sign up 
   */
  async function handleSignUp(){
    checkInputs();
    if(error){
      return ''
    }
          
    try {
      await Auth.signUp({
        username: username,
        password: password,
        attributes: {
          name: name,
          email: email,
          phone_number: fullPhone,
          'custom:cpf':'',
          "custom:permission": role          
        }
      })
      clearInputs();
    } catch (error) {
      console.log(error)  
    }
  }

  return ( 
    <Col sm={5} xs={12}>
      <Card className={styles.card}>
        <Card.Header className={styles.cardHeader}>
          <Card.Title className={styles.cardTitle}>
            Cadastro
          </Card.Title>
        </Card.Header>
        <Card.Body className={styles.cardBody}>
          <Col className={styles.body} sm={8}>
            {
              error ? 
              <Alert variant='danger'>
                {returnErrors()}
              </Alert>
              :
              ''
            }
            <Form className={styles.form}>
              <Form.Control
                placeholder='Nome'
                value={name}
                onChange={(e) => setName(e.target.value)}
                />
              <Form.Control
                placeholder='Usuário'
                value={username}
                onChange={(e) => setUserName(e.target.value)}
                />
              <Form.Control 
                placeholder='E-mail'
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                />
              <Form.Control 
                placeholder='Senha'
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                type='password'
                autoComplete='on'
                />
              <Form.Control 
                placeholder='Confirmação da senha'
                value={confirmPassword}
                onChange={(e) => setConfirmPassword(e.target.value)}
                type='password'
                autoComplete='on'
                />
              <PhoneInput 
                labels={pt}
                defaultCountry="BR"
                placeholder = "Telefone"
                international
                initialValueFormat='national'
                countryCallingCodeEditable={false}
                value={fullPhone}
                onChange={(e) => setFullPhone(e)}
                className={styles.inputPhone}                
              />
              <Row>
                <Row className={styles.checkedPassword}>
                  {password.length >= 8 ?                 
                    <Icon 
                    path={mdiCheckBold}
                    size={1.75}
                    color='green'  
                    />                 
                    :                
                    <Icon 
                    path={mdiWindowClose}
                    size={1.75}
                    color='red'
                    />                
                  } 8 Caracteres              
                </Row>
                <Row className={styles.checkedPassword}>
                  {hasNumber(password) ?                 
                    <Icon 
                    path={mdiCheckBold}
                    size={1.75}
                    color='green'  
                    />                 
                    :                
                    <Icon 
                    path={mdiWindowClose}
                    size={1.75}
                    color='red'
                    />               
                  } Número              
                </Row>
                <Row className={styles.checkedPassword}>
                  {hasUppercase(password) ?                 
                    <Icon 
                    path={mdiCheckBold}
                    size={1.75}
                    color='green'  
                    />                 
                    :                
                    <Icon 
                    path={mdiWindowClose}
                    size={1.75}
                    color='red'
                    />               
                  } Letra Maiuscula              
                </Row>
                <Row className={styles.checkedPassword}>
                  {hasLowercase(password) ?                 
                    <Icon 
                    path={mdiCheckBold}
                    size={1.75}
                    color='green'  
                    />                 
                    :                
                    <Icon 
                    path={mdiWindowClose}
                    size={1.75}
                    color='red'
                    />               
                  } Letra Minúscula              
                </Row>
                <Row className={styles.checkedPassword}>
                  {password === confirmPassword && password.length > 0 ?                 
                    <Icon 
                    path={mdiCheckBold}
                    size={1.75}
                    color='green'  
                    />                 
                    :                
                    <Icon 
                    path={mdiWindowClose}
                    size={1.75}
                    color='red'
                    />               
                  } Senhas conferem            
                </Row>
              </Row>
              <Form.Select
                value={role}
                onChange={(e) => setRole(e.target.value)}
                className={styles.selectInput}
              >
                <option value={-1}>Selecione uma opção</option>
                {roles.map((value, index) => <option value={value.value} key={index}>{value.name}</option>)}
              </Form.Select>
              <Button
                className={[button.colorWhite, button.withMargin]}
                onClick={() => handleSignUp()}
              >
                Cadastrar
              </Button>
            </Form>
          </Col>
        </Card.Body>
      </Card>
    </Col>
   );
}

export default CardRegister;