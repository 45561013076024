import { 
  SignalWifi0BarOutlined, 
  SignalWifi1BarOutlined, 
  SignalWifi2BarOutlined, 
  SignalWifi3BarOutlined, 
  SignalWifi4BarOutlined, 
  SignalWifiOffOutlined 
} from "@material-ui/icons";

/**
 * get wifi icon based in device wifi
 * @param {Boolean} isSubscribed 
 * @param {Number} deviceWifi 
 * @returns 
 */
 export function statusWifi(isSubscribed, deviceWifi) {
  if(!isSubscribed) return <SignalWifiOffOutlined />

  switch (deviceWifi) {
    case -1: 
      return <SignalWifiOffOutlined />;
    case 0:
      return <SignalWifi0BarOutlined />;
    case 1:
      return <SignalWifi1BarOutlined />;
    case 2:
      return <SignalWifi2BarOutlined />;
    case 3:
      return <SignalWifi3BarOutlined />;
    case 4:
      return <SignalWifi4BarOutlined />;
    default:
      return <SignalWifi0BarOutlined />;
  }
}

/**
  * verify device status and return object with background color
  * @param {Object.keys} deviceInfos 
  * @param {Boolean} isSubscribed 
  * @returns {Object.keys}
  */
  export function verifyStatus(deviceInfos, isSubscribed){
  if(deviceInfos.device.error) 
    return {backgroundColor: '#ff6161'};
    
  if(!isConnected(isSubscribed, deviceInfos)) 
    return {backgroundColor: '#aaa'}
  
  if(deviceInfos.device.heating === 1) 
    return {backgroundColor: '#ff993f'};

  return {backgroundColor: '#9dd0df'}
}

/**
 * verify if device is connected
 * @returns {boolean} `bool`
 */
  export function isConnected(isSubscribed, deviceInfos){
    if(!isSubscribed || deviceInfos.device.status !== 'connected')
      return false
    
    return true;
  }