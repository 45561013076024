import React, { useEffect, useState } from 'react'
import { useSearchParams } from 'react-router-dom';
import { API } from 'aws-amplify';

import styles from "./style.module.scss"
import GrayRectangle from 'assets/Icons/gray_rectangle'

export default function SupportLog() {
  const [params] = useSearchParams();
  const [selectedUserName, setSelectedUserName] = useState("");
  const [supportLog, setSupportLog] = useState([{
    admin: String,
    product: String,
    question: String,
    requester: String,
    solution: String,
    timestamp: String,
    username: String
  }]);

  function dateConverter(timestamp) {
    let day = new Date(timestamp).getDate();
    let month = new Date(timestamp).getMonth()+1;
    let year = new Date(timestamp).getFullYear();
    const date = `${day.toString().length < 2 ? "0" + day : day}/${month.toString().length < 2 ? "0" + month : month}/${String(year).slice(2, 4)}`
    return date;
  }

  async function getData(){
    try{
      const response = await API.get(
        "SmartlyAPI",
        `/support/reports`,
      );
      const sorted = response.body.response.sort(function(a, b){return b.timestamp - a.timestamp});
      return sorted;
    }catch(e){
      console.log(e);
    }
  }

  useEffect(() => {
    getData().then((logs) => {
      setSupportLog(logs)
    })
    setSelectedUserName(params.get("user"));
  })

  console.log(selectedUserName)

  return (
    <>
    <div className={styles.header}>
      Histórico de Atendimentos
    </div>
    {supportLog.map((support) => {
      return support.username === selectedUserName &&
      (
      <div className={styles.support}>
        <div className={styles.info}>
          <div className={styles.infoField}>
            <div style={{fontSize: "16px", fontWeight: 500}}>Data:</div>
            <div style={{fontSize: "14px", fontWeight: 400}}>{dateConverter(parseInt(support.timestamp)*1000)}</div> 
          </div>
          <div className={styles.infoField}>
            <div style={{fontSize: "16px", fontWeight: 500}}>Solicitante:</div>
            <div style={{fontSize: "14px", fontWeight: 400}}>{support.requester}</div> 
          </div>
          <div className={styles.infoField}>
            <div style={{fontSize: "16px", fontWeight: 500}}>Produto:</div>
            <div style={{fontSize: "14px", fontWeight: 400}}>{support.product}</div> 
          </div>
        </div>
        <GrayRectangle/>
        <div className={styles.question}>
          <div className={styles.questionField}>
            <div style={{fontSize: "16px", fontWeight: 500}}>Questionamento:</div>
            <div style={{fontSize: "12px", fontWeight: 400}}>{support.question}</div> 
          </div>
          <div className={styles.questionField}>
            <div style={{fontSize: "16px", fontWeight: 500}}>Solução:</div>
            <div style={{fontSize: "12px", fontWeight: 400}}>{support.solution}</div> 
          </div>
        </div>
      </div>
      )
    })}
    </>
  )
}
