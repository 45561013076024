import ProtectPageImport from "./ProtectPage";
import NavBarImport from "./NavBar";
import DeviceListImport from "./DeviceList";
import { Thermostat, HotCloset } from "./DeviceCard";
import CardRegisterImport from "./CardRegister";

export { Thermostat, HotCloset };
export const DeviceList = DeviceListImport;
export const NavBar = NavBarImport;
export const ProtectPage = ProtectPageImport;
export const CardRegister = CardRegisterImport;
