import { useState, useMemo } from 'react';
import { Table, Form, Button, Modal } from 'react-bootstrap';
import button from 'assets/Button/button.module.scss';
import styles from '../LogCard.module.scss'

const LargeTable = ({ logs }) => {
  const properties = useMemo(() => {
    const attributesSet = new Set();
    logs.forEach(obj => {
      Object.keys(obj).forEach(key => {
        attributesSet.add(key);
      });
    });
    return Array.from(attributesSet).filter(prop => prop !== 'Device');
  }, [logs]);

  const [columnItem, setColumnItem] = useState(properties);
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const handleColumnChange = (propertie) => {
    setColumnItem(prevState =>
      prevState.includes(propertie)
        ? prevState.filter(item => item !== propertie)
        : [...properties.filter(p => prevState.includes(p) || p === propertie)]
    );
  };

  const handleClearFilters = () => {
    setColumnItem([]);
  };

  const handleSelectAll = () => {
    setColumnItem(properties);
  };

  return (
    <div>
      <Button variant="primary" onClick={handleShow} className={button.colorWhite}>Filtrar</Button>
      <Modal show={show} onHide={handleClose} animation={false}>
        <Modal.Header closeButton>
          <Modal.Title>Selecionar Filtros</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {properties.map((propertie) => (
            <Form.Check
              inline
              key={propertie}
              label={propertie}
              name={propertie}
              checked={columnItem.includes(propertie)}
              onChange={() => handleColumnChange(propertie)}
            />
          ))}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClearFilters}>Limpar</Button>
          <Button variant="secondary" onClick={handleSelectAll}>Todos</Button>
        </Modal.Footer>
      </Modal>
      <Table responsive 
             size='sm'
             striped 
             bordered>
        <thead>
          <tr>
            {columnItem.map((propertie, index) => (
              <th style={{ textAlign: "center"}}  key={index}>{propertie}</th>
            ))}
          </tr>
        </thead>
        <tbody>
          {logs.map((item, rowIndex) => (
            <tr key={rowIndex}>
              {columnItem.map((propertie, colIndex) => (
                <td className={styles.infoTable} key={colIndex}>{item[propertie]}</td>
              ))}
            </tr>
          ))}
        </tbody>
      </Table>
    </div>
  );
};

export default LargeTable;
