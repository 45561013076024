import Amplify, { PubSub } from "aws-amplify";
import { AWSIoTProvider } from "@aws-amplify/pubsub";

Amplify.addPluggable(
  new AWSIoTProvider({
    aws_pubsub_region: "us-east-1",
    aws_pubsub_endpoint:
      "wss://a1jigf4d2ash6s-ats.iot.us-east-1.amazonaws.com/mqtt",
  })
);

/**
 * @param {String} topic 
 * @param {Function} callback 
 */
export function subscribe(topic, callback = ()=>{}){
  return PubSub.subscribe(topic)
  .subscribe(
    {
      next: callback,
      error: (error) => console.log(error),
      complete: () => console.log('Done')
    }
  );
}

export function publish(topic, msg = {}){
  return PubSub.publish(topic, msg);
}

export { PubSub };