import { useEffect } from "react";
import { authorization } from 'service/Authorizarion';

/**
 * @returns verify user permissions before access page
 */
function Authorization({ setAuthorizated }) {
  useEffect(async () => {
    setAuthorizated(authorization())
  },[])
  
  return ( 
    <></>
   );
}

export default Authorization;