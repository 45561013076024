import { Auth } from "aws-amplify";
import jwtDecode from "jwt-decode";


/**
 * @param {String} path 
 */
export function splitPath(path){
  var testPath = String(path).split('/');
  return testPath;
}

/**
 * verify user permissions 
 * @returns `bool`
 */
export function authorization(){
  var decode = jwtDecode(sessionStorage.getItem('jwtToken'))
  
  if(Number(decode.exp + "000") <= Date.now()){
    return false
  }

  const path = splitPath(window.location.pathname);
  switch(path[2]){
    case 'users':
      if( customPermission() === 'admin' || 
          customPermission() === 'support'){
          return true;
        }
      return false;
    
    case 'searchDevice':
      if( customPermission() === 'admin' || 
          customPermission() === 'support' ||
          customPermission() === 'installer'){
        return true;
      }
      return false;

    case 'register':
      if(customPermission() === 'admin'){
        return true;
      }
      return false;

    case 'form':
      if( customPermission() === 'admin' || 
          customPermission() === 'support'
        ){
          return true
      }
      return false
    
    case 'support':
      if(customPermission() === 'admin'){
        return true;
      }
      return false;

    default:
      return false;    
  }  
}

/**
 * @returns {String} user custom permission
 */
export function customPermission(){
  var decode = jwtDecode(sessionStorage.getItem('jwtToken'))
  return decode['custom:permission'];
}

export async function getAccount(){
  const account = (await Auth.currentUserInfo()).username;
  return account;
}