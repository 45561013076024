import React from "react";

const Devices = React.createContext(
  {
    device: [],
    addDevice: (devices) => {},
    updateDevice: (device) => {},
    removeDevice: (device) => {},
    updateDevice: (data, deviceId) => {}
  }
);
Devices.displayName = "Devices";

export default Devices;